import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SfMessageService } from 'src/app/core/services/core/sf-message.service';
import { ScAddressService } from 'src/app/core/services/sc/sc-address.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScAddressList, ScAddressOutput } from 'src/app/models/response/sc-address.response';
import { ScCompanyInfoInput } from 'src/app/pages/sc-company/sc-company.model';
import { ScCompanyService } from 'src/app/pages/sc-company/sc-company.service';

import { BreadCrumb } from 'src/app/shared/components/sf-breadcrumb/sf-breadcrumb.model';



@Component({
    selector: 'sc-address-grid',
    templateUrl: './sc-address-grid.component.html',
    styleUrls: ['./sc-address-grid.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class ScAddressGridComponent implements OnInit {

    public language: string = "pt-BR";
    public message: string = "";
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-primary p-m-1 mr-1 text-lg font-semibold";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1 text-lg font-semibold";
    public BREAD_CRUMB_LIST: Array<BreadCrumb> = [];

    public list!: ScAddressOutput[];
    public item: ScAddressOutput = {};

    public isClient: boolean = true;
    public showDialog: boolean = false;

    public flUpdate: boolean = false;
    public loading: boolean = false;




    public formattedAddress: string = "";


    constructor(private messageService: SfMessageService,
        private translateService: TranslateService,
        private translateConfigService: TranslateConfigService,
        private addressService: ScAddressService,
        private router: Router) { }

    ngOnInit(): void {
        this.getList();
        this.translate();

        //TODO ScAdddressGridComponent - Update from cartorio
        this.flUpdate = true;

    }


    getList() {
        this.addressService.getList().subscribe(
            {
                next: (res) => {
                    if (res)
                        this.list = res.data;
                },
                error: (err) => {
                    console.log(`ScAddressGridComponent - getList : ${err}.`);
                }
            });
    }

    setMainAddress(item: ScAddressOutput) {
        if (item.isMainAddress == false) {
            this.addressService.setMainAddress(item, this.isClient).subscribe(
                {
                    next: (res) => {
                        if (res)
                            this.getList();
                        this.messageService.messageSuccess(this.translateService.instant('message.record_updated'));
                    },
                    error: (err) => {
                        console.log(`ScAddressGridComponent - setMainAddress : ${err}.`);
                        this.message = this.translateService.instant('api_response.' + err.error);

                        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                            this.message = this.translateService.instant('api_response.ERROR');

                        this.messageService.messageError(this.translateService.instant(this.message));
                    }
                });
        }
    }

    addItem() {
        this.item.id = null;
        this.showDialog = true;
    }

    updItem(item: ScAddressOutput) {
        this.item = item;
        this.showDialog = true;
    }







    formattedAddressSubmitted(formattedAddress) {
        this.getList();
        this.formattedAddress = formattedAddress;
    }

    addressSubmitted(addressId) {
        this.showDialog = false;

        if (addressId == null || addressId == "" || addressId == undefined) {
            // this.item.id = null;
            console.log('addressId: ', addressId);
        }
        else {
            //this.item.id = addressId;
            console.log('addressId: ', addressId);
        }

    }




    translate() {
        this.language = this.translateConfigService.getLanguage();

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
            await sleep(50);
            const lab0 = this.translateService.instant('label.home') + ' ';
            const lab1 = ' ' + this.translateService.instant('label.addresses');

            this.BREAD_CRUMB_LIST = [
                { label: lab0, routerLink: '/', selected: false },
                { label: lab1, routerLink: '/address-list', selected: true }
            ]


        })();




    }


}
