import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationGuard } from "src/app/core/guards/authorization.guard";
import { AuthenticationGuard } from "src/app/core/guards/authentication.guard";
import { ScUserProfileComponent } from "./sc-user-profile/sc-user-profile.component";
import { ScUserGridComponent } from "./sc-user-grid/sc-user-grid.component";




  const routes: Routes = [
    {
        path: '', component: ScUserGridComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data:{
            roles:['SF_ADMIN','ROLE_SF_SELLER','ROLE_ADMIN','ROLE_MANAGER']
        }
    },
    {
        path: 'profile', component: ScUserProfileComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data:{
            roles:['SF_ADMIN','ROLE_SF_SELLER','ROLE_ADMIN','ROLE_MANAGER']
        }
    }
  ];

  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class ScUserRoutingModule { }
