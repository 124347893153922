import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
//import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
//import { ImageCroppedEvent} from 'ngx-image-cropper';

import { MessageService, ConfirmationService, TreeNode } from "primeng/api";

import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScUserDetailOutput, ScUserOutput} from '../../../models/response/sc-user.model';
import { DropDown, GenericFeedback } from 'src/app/shared/model/base-model';
import { ScUserService } from '../../../core/services/sc/sc-user.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { ScCompanyService } from '../../../pages/sc-company/sc-company.service';
import { ScRoleService } from '../../../pages/sc-role/sc-role.service';
import { SfMessageService } from 'src/app/core/services/core/sf-message.service';


// class ImageSnippet {
//     constructor(public src: string, public file: File) {}
// }

@Component({
    selector: 'sc-user-detail',
    templateUrl: './sc-user-detail.component.html',
    styleUrls: ['./sc-user-detail.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class ScUserDetailComponent implements OnInit, OnChanges {


    @Input() id: string;
    @Output() chosedItem = new EventEmitter();

    public receivedData: any;
    public receivedAction: string;
    public feedbackData: GenericFeedback = {};

    public item: ScUserOutput = {};
    public userId: string = null;
    public language: string = "pt-BR";
    public origin: string = "ScUserDetailComponent";

    public treeItem: TreeNode;
    public roleItem: DropDown;
    public userSignForList: DropDown[] = [];
    public selectedSkills: DropDown[] = [];

    public detailItem: ScUserDetailOutput = {};





    //access
    public hasClerk: boolean = true;
    public canChangeLogin: boolean = true;
    public canUpdate: boolean = true;
    public hasPicture: boolean = false;
    public invalidEmail: boolean = false;
    public isMyData: boolean = false;
    public isLoaded: boolean = false;




    public message: string = "";
    public pageMessage: string = "";
    public errorMessage: string = "";
    public msgPrefix: string = '';
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-danger p-m-1 mr-1";
    public classBtnReset: string = "p-button p-button-warning p-m-1 mr-1";






    // public userPicDialog: boolean = false;
    // public imageChangedEvent: any = '';
    // public croppedImage: any;
    // public showCropper = false;
    // public fileList: any[] = [];
    // public imageBlob: any;
    // public imageUrl: string;






    constructor(private userTokenService: UserTokenService,
                private userService: ScUserService,
                private roleService: ScRoleService,
                private companyService: ScCompanyService,
                private confirmationService: ConfirmationService,
                private messageService: SfMessageService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private router: Router) { }

    ngOnInit(): void {

        this.translate();
        this.canUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'ROLE_ADMIN', 'ROLE_MANAGER']);
        this.canChangeLogin = this.companyService.canChangeLogin(this.userTokenService.getCompanyId());
        this.hasClerk = this.userTokenService.getCompanyConfig('CLERK');
        this.userId = this.userTokenService.getUserId();

        if (this.canUpdate == false)
            this.classBtnDel = this.classBtnReset = "p-button p-button-secondary p-m-1 mr-1";

        if (this.id == null || this.id == undefined || this.id == ""){
            this.id = this.userId;
            this.isMyData = true;
            //this.getUserPersonalData();
        }
        // else if (this.id != "new"){
        //     this.getItem();
        // }
        // else{
        //     this.item.isActive = true;
        //     this.item.isBlocked = false;
        //     this.item.isEmailConfirmed = true;
        //     this.item.isFirstAccess = true;
        //     this.item.languageCode = this.language;
        // }

        console.log('user init');
        this.getUserData();



    }

    ngOnChanges(): void {

        console.log('user changes');
        this.feedbackAction();
        // if (this.receivedAction == "save")
        //     this.saveItem();
    }

    getUserData(){
        this.userService.dataChanged.subscribe((data: any) => {
            this.receivedData = data;

            if (this.receivedData == null || this.receivedData == "" || this.receivedData === undefined){
                this.item.isActive = true;
                this.item.isBlocked = false;
                this.item.isEmailConfirmed = true;
                this.item.isFirstAccess = true;
                this.item.languageCode = this.language;
            }
            else {
                this.item = this.receivedData;
            }
            this.isLoaded = true;
          });

    }

    feedbackAction(){
        this.userService.actionChanged.subscribe((data: any) => {
            this.receivedAction = data;
                this.saveItem();

          });
    }



    // getItem() {
    //     this.isMyData = false;
    //     this.userService.getItem(this.id).subscribe(
    //     {
    //     next: (res) => {
    //         if (res)
    //         {
    //             this.item = res.data;
    //             if (this.item != null){
    //                 this.hasPicture = !(this.item.pictureUri == undefined || this.item.pictureUri == null || this.item.pictureUri == "");
    //                 //this.getItemDetail();

    //             }
    //         }
    //         this.translate();
    //     },
    //     error: (err) => console.log(`UserDetail - getItem : ${err}.`)
    //     });
    // }

    // getUserPersonalData() {
    //     this.isMyData = true;
    //     this.userService.getUserPersonalData().subscribe(
    //     {
    //     next: (res) => {
    //         if (res)
    //         {
    //             this.item = res.data;
    //             if (this.item != null){
    //                 this.hasPicture = !(this.item.pictureUri == undefined || this.item.pictureUri == null || this.item.pictureUri == "");
    //                 //this.getItemDetail();

    //             }
    //         }
    //         this.translate();
    //     },
    //     error: (err) => console.log(`UserDetail - getItem : ${err}.`)
    //     });
    // }

    //TODO USER DETAIL => SKILLS
    // getItemDetail() {

    //     this.userService.getItemDetail(this.id).subscribe(
    //     {
    //     next: (res) => {
    //         if (res)
    //         {
    //             this.detailItem = res.data;
    //             this.item.scOrganizationStructureId = this.detailItem.scOrganizationStructureId;
    //             this.selectedSkills = this.detailItem.skills;
    //             this.item.skills = this.detailItem.skills;
    //         }
    //     },
    //     error: (err) => console.log(`UserDetail - getItemDetail : ${err}.`)
    //     });
    // }




    // getRole() {
    //     this.roleService.getItemByUserId(this.item.id, this.language).subscribe(
    //     {
    //     next: (res) => {
    //         if (res)
    //             this.item.scRoleId = res.data.id;

    //     },
    //     error: (err) => console.log(`${this.origin} - getRole: ${err}.`)
    //     });
    // }

    saveItem() {
        if (this.item.email.trim()) {
            if (this.item.id) {
                if (this.isMyData == true){
                    this.userService
                    .updUserPersonalData(this.item, this.language)
                    .subscribe(
                        {
                            next: () => {
                                this.messageService.messageSuccess(this.msgPrefix+this.translateService.instant('message.record_updated'));
                            },
                            error: (err) => {
                                console.log(`${this.origin} - saveItem - Update : ${err}.`)
                                this.message = this.translateService.instant('api_response.'+err.error);

                                if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                                    this.message = this.translateService.instant('api_response.ERROR');

                                this.messageService.messageError(this.msgPrefix+this.translateService.instant(this.message));
                            }
                        });
                }


                else{
                    this.userService
                    .updItem(this.item, null, this.userId, this.language)
                    .subscribe(
                        {
                            next: () => {
                                this.messageService.messageSuccess(this.msgPrefix+this.translateService.instant('message.record_updated'));
                            },
                            error: (err) => {
                                console.log(`${this.origin} - saveItem - Update : ${err}.`)
                                this.message = this.translateService.instant('api_response.'+err.error);

                                if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                                    this.message = this.translateService.instant('api_response.ERROR');

                                this.messageService.messageError(this.msgPrefix+this.translateService.instant(this.message));
                            }
                        });
                }
            }
            else {
                this.userService
                .addItem(this.item, this.userId, this.language)
                .subscribe({
                    next: () => {
                        this.messageService.messageSuccess(this.msgPrefix+this.translateService.instant('message.record_updated'));
                    },
                    error: (err) => {
                        console.log(`${this.origin} - saveItem - Update : ${err}.`)
                        this.message = this.translateService.instant('api_response.'+err.error);

                        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                            this.message = this.translateService.instant('api_response.ERROR');

                        this.messageService.messageError(this.msgPrefix+this.translateService.instant(this.message));

                    }
                });
            }
        }
    }


    reciverFeedbackRole(event)
    {
        this.roleItem = event;
        if (this.roleItem != null && this.roleItem.id != undefined)
        {
            this.item.scRoleId = this.roleItem.id;
        }
    }

    reciverFeedbackStructure(event)
    {
        this.treeItem = event;
        if (this.treeItem != null && this.treeItem.key != undefined && this.treeItem.key != "")
        {
            this.item.scOrganizationStructureId = this.treeItem.key;
        }
    }

    reciverFeedbackSkill(select: DropDown[])
    {
        this.selectedSkills = select;
        this.item.skills = this.selectedSkills;
    }

    reciverFeedbackUserClerk(event)
    {
        this.item.scUserSignatureId = event;
    }

    // feedback(){
    //     this.chosedItem.emit(this.id)
    // }

    translate()
    {
        this.language = this.translateConfigService.getLanguage();

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
             await sleep(50);
             this.msgPrefix = this.translateService.instant('label.user_details')+' - ';

         })();




    }

    // fileChangeEvent(event: any): void {

    //   //  console.log("fileChangeEvent");
    //     this.userPicDialog = true;
    //     this.imageChangedEvent = event;

    //     // this.tipoFoto = 'png';

    //     // if (event.target.files && event.target.files[0]) {
    //     // let file =  event.target.files[0]
    //     // if ((file.name.includes('jpg')) || (file.name.includes('jpeg')))
    //     //     this.tipoFoto = 'jpeg';

    //     // //    console.log(file);

    //     //     var selectedFile = event.target.files[0];



    //         //this.fileList.push(selectedFile);


    //     // var reader = new FileReader();
    //     // reader.readAsDataURL(event.target.files[0]); // read file as data url
    //     // }


    // }

    // imageCropped(event: ImageCroppedEvent) {
    //     //  console.log('Cropped Image Event:', event);
    //     this.croppedImage = event.base64;
    //     this.fileList.push(this.croppedImage);

    //     this.imageBlob = event.blob;
    //     this.imageUrl = event.objectUrl;
    //     this.fileList = [];

    //     fetch(this.imageUrl)
    //         .then((res) => res.blob())
    //         .then((myBlob) => {
    //             const myFile = new File([myBlob], 'image.png', {type: myBlob.type});
    //             this.fileList.push(myFile);
    //         });
    // }

    // imageLoaded() {
    //     this.showCropper = true;
    //     // console.log('cropper load');
    // }
    // cropperReady() {
    //     // cropper ready
    //     // console.log('cropper ready');
    // }
    // loadImageFailed() {
    //     // show message
    //     // console.log('cload fail');
    // }

    // hidePicDialog(): void {
    //     this.userPicDialog = false;
    // }



    // savePic()
    // {
    //     if (this.item.id) {
    //         this.userService.uploadItem(this.fileList, this.item.id)
    //         .subscribe(
    //             {
    //                 next: () =>
    //                 {
    //                     this.item.pictureUri = this.imageUrl;
    //                     this.hasPicture = true;
    //                     this.userPicDialog = false;
    //                     this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.picture_updated'));
    //                 },
    //                 error: (err) => {
    //                     this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.errorMessage);
    //                     console.log(`UsuarioGrid - uploadItem`)
    //                     console.log(err)
    //                 }
    //             });
    //     }
    //     else {
    //         this.item.pictureUri = this.imageUrl;
    //         this.hasPicture = true;
    //         this.userPicDialog = false;
    //     }
    // }

    // cancel()
    // {
    //     this.confirmationService.confirm({
    //         message: this.translateService.instant('message.confirm_cancel'),
    //         header: this.translateService.instant('title.confirmation'),
    //         acceptLabel: this.translateService.instant('button.yes'),
    //         rejectLabel: this.translateService.instant('button.no'),
    //         acceptButtonStyleClass: 'p-button-success',
    //         rejectButtonStyleClass: 'p-button-danger',
    //         icon: 'pi pi-exclamation-triangle',
    //         accept: () => {
    //             this.getItem();
    //         }
    //     });
    // }

    // close()
    // {
    //     this.getItem();
    //     this.router.navigate(['user']);
    // }



    checkEmail(email: string)
    {


    }

    checkLogin(login: string)
    {


    }

}
