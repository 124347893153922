import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DropDownMeta } from 'src/app/shared/model/base-model';
import { WoOrderOrderServiceList, WoOrderOrderServiceOutput, WoOrderServiceItem, WoOrderServiceList, WoOrderServiceOutput, WoOrderServiceTaxType, WoOrderServiceTaxTypeOutput } from './wo-order-service.model';



@Injectable({
    providedIn: 'root'
  })

export class WoOrderServiceService{

    private environmentUrl: string = '';


    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/OrderService/';
    }



//======================================================
//================== ORDER SERVICE =====================
//======================================================

    getItem(id: string): Observable<WoOrderServiceItem> {
        let url = this.environmentUrl+ id;
        return this.httpClient.get<WoOrderServiceItem>(url);
    }

    getList() : Observable<WoOrderServiceList>{
        let url = this.environmentUrl;
        return this.httpClient.get<WoOrderServiceList>(url);
    }

    addItem(model: WoOrderServiceOutput){
        let url = this.environmentUrl;
        return this.httpClient.post(url, model);
    }

    updItem(model: WoOrderServiceOutput){
        let url = this.environmentUrl+ model.id;
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        let url = this.environmentUrl+id;
        return this.httpClient.delete(url);
    }

    getOrderServiceDropDownList(language: string): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown?language=" + language;
        return this.httpClient.get<DropDownMeta>(url);
    }





//======================================================
//================== ORDER ORDER SERVICE =====================
//======================================================

    getOrderOrderServiceItem(id: string): Observable<WoOrderOrderServiceOutput> {
        let url = this.environmentUrl+"OrderOrderService/"+ id;
        return this.httpClient.get<WoOrderOrderServiceOutput>(url);
    }

    getOrderOrderServiceList(woOrderId: string) : Observable<WoOrderOrderServiceList>{
        let url = this.environmentUrl+"OrderOrderService?search="+woOrderId;
        return this.httpClient.get<WoOrderOrderServiceList>(url);
    }

    getOrderOrderServiceSummaryList(woOrderId: string) : Observable<WoOrderOrderServiceList>{
        let url = this.environmentUrl+"OrderOrderServiceSummary/"+woOrderId;
        return this.httpClient.get<WoOrderOrderServiceList>(url);
    }


    getOrderOrderServiceSummaryClientList(woOrderId: string) : Observable<WoOrderOrderServiceList>{
        let url = this.environmentUrl+"OrderOrderServiceSummary/Client/"+woOrderId;
        console.log(url);
        return this.httpClient.get<WoOrderOrderServiceList>(url);
    }

    addOrderOrderServiceItem(model: WoOrderOrderServiceOutput, userId: string, language: string){
        let url = this.environmentUrl+"OrderOrderService/";
        return this.httpClient.post(url, model);
    }

    updOrderOrderServiceItem(model: WoOrderOrderServiceOutput, userId: string, language: string){
        let url = this.environmentUrl+"OrderOrderService/"+ model.id;
        return this.httpClient.put(url, model);
    }

    delOrderOrderServiceItem(id: string) {
        let url = this.environmentUrl+"OrderOrderService/"+id;
        return this.httpClient.delete(url);
    }

    getOrderOrderServiceDropDownList(language: string): Observable<DropDownMeta> {
        let url = this.environmentUrl+"OrderOrderService/DropDown?language=" + language;
        return this.httpClient.get<DropDownMeta>(url);
    }



    //=========================================================
    //================== OrderServiceTaxType ==========================
    //=========================================================
    getTaxTypeItem(id: string): Observable<WoOrderServiceTaxTypeOutput> {
        let url = this.environmentUrl+"TaxType/" + id;
        return this.httpClient.get<WoOrderServiceTaxTypeOutput>(url);
    }

    getTaxTypeList() : Observable<WoOrderServiceTaxTypeOutput[]>{
        let url = this.environmentUrl+"TaxType/";
        return this.httpClient.get<WoOrderServiceTaxTypeOutput[]>(url);
    }

    addTaxTypeItem(model: WoOrderServiceTaxType){
        let url = this.environmentUrl+"TaxType/";
        return this.httpClient.post(url, model);
    }

    updTaxTypeItem(model: WoOrderServiceTaxType){
        let url = this.environmentUrl+"TaxType/" + model.id;
        return this.httpClient.put(url, model);
    }

    delTaxTypeItem(id: string) {
        let url = this.environmentUrl+"TaxType/"+id;
        return this.httpClient.delete(url);
    }

    getTaxTypeDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "TaxType/DropDown";
        return this.httpClient.get<DropDownMeta>(url);
    }

    //=========================================================
    //================ OrderServiceSkill =====================
    //=========================================================

    getListByUser(woOrderTypeId: string) : Observable<WoOrderServiceList>{
        let url = this.environmentUrl + 'User/Skill/' + woOrderTypeId;
        return this.httpClient.get<WoOrderServiceList>(url);
    }

    //=========================================================
    //===================== Company ===========================
    //=========================================================

    getListByCompany(scCompanyId: string) : Observable<WoOrderServiceList>{
        let url = this.environmentUrl + 'Company/'+scCompanyId;
        console.log(url);
        return this.httpClient.get<WoOrderServiceList>(url);
    }
}


