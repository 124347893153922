import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent} from 'ngx-image-cropper';

import { MessageService, ConfirmationService} from "primeng/api";

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScUserDetailOutput, ScUserOutput, ScUserStatusHist } from '../../../models/response/sc-user.model';
import { DropDown } from 'src/app/shared/model/base-model';
import { ScUserService } from '../../../core/services/sc/sc-user.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { BreadCrumb } from 'src/app/shared/components/sf-breadcrumb/sf-breadcrumb.model';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';



class ImageSnippet {
    constructor(public src: string, public file: File) {}
}

@Component({
    selector: 'sc-user-profile',
    templateUrl: './sc-user-profile.component.html',
    styleUrls: ['./sc-user-profile.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class ScUserProfileComponent implements OnInit, OnChanges {

    public receivedFeedback: any;
    public SendUserData: ScUserOutput = {};


    public language: string = "pt-BR";
    public item: ScUserOutput = {};
    public detailItem: ScUserDetailOutput = {};
    public userId: string = null;
    public activeIndex: number = 1;
    public userSignForList: DropDown[] = [];

    public hasPicture: boolean = false;




    public message: string = "";
    public pageMessage: string = "";
    public errorMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";

    public labelAccessPermission: string = "";
    public labelUserRegistration: string = "";
    public labelUserHistory: string = "";
    public labelUserDetails: string = "";

    public userPicDialog: boolean = false;
    public imageChangedEvent: any = '';
    public croppedImage: any;
    public showCropper = false;
    public fileList: any[] = [];
    public imageBlob: any;
    public imageUrl: string;


    public BREAD_CRUMB_LIST: Array<BreadCrumb> = [];


    //access


//    @Input() userId: string;
  //  @Output() closeEvent = new EventEmitter();




    constructor(private userTokenService: UserTokenService,
                private userService: ScUserService,
                private personService: ScPersonService,
                private confirmationService: ConfirmationService,
                private notificationsService: NotificationsService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService
                ) { }

    ngOnInit(): void {
        this.getItem();
        this.translate();
        //this.hasClerk = this.userTokenService.getCompanyConfig('CLERK');
    }

    ngOnChanges(): void {
        this.reciverFeedbackService();
    }

    getItem() {
        this.userService.getUserPersonalData().subscribe(
        {
        next: (res) => {
            if (res)
            {
                this.item = res.data;
                this.userService.sendData(this.item);
                this.personService.sendData(this.item.scPerson);
                if (this.item != null){
                    this.hasPicture = !(this.item.pictureUri == undefined || this.item.pictureUri == null || this.item.pictureUri == "");
                }
            }
        },
        error: (err) => console.log(`UserProfile - getItem : ${err}.`)
        });
    }

    // getItemDetail() {

    //     this.userService.getItemDetail(this.userId).subscribe(
    //     {
    //     next: (res) => {
    //         if (res)
    //         {
    //             this.detailItem = res.data;
    //             this.item.skills = this.detailItem.skills;
    //         }
    //     },
    //     error: (err) => console.log(`UserProfile - getItemDetail : ${err}.`)
    //     });
    // }


    // getTimeLine() {

    //     this.userService.geTimeLine(this.userId, this.language).subscribe(
    //     {
    //         next: (res) =>
    //         {
    //             this.userStatusHist = res.data;

    //         },
    //         error: (err) => console.log(`UserProfile - getTimeLine : ${err}.`)
    //     });
    // }




    fileChangeEvent(event: any): void {
        this.userPicDialog = true;
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        //  console.log('Cropped Image Event:', event);
        this.croppedImage = event.base64;
        this.fileList.push(this.croppedImage);

        this.imageBlob = event.blob;
        this.imageUrl = event.objectUrl;
        this.fileList = [];

        fetch(this.imageUrl)
            .then((res) => res.blob())
            .then((myBlob) => {
                const myFile = new File([myBlob], 'image.png', {type: myBlob.type});
                this.fileList.push(myFile);
            });
    }

    imageLoaded() {
        this.showCropper = true;
        // console.log('cropper load');
    }
    cropperReady() {
        // cropper ready
        // console.log('cropper ready');
    }
    loadImageFailed() {
        // show message
        // console.log('cload fail');
    }

    hidePicDialog(): void {
        this.userPicDialog = false;
    }

    save(){
        this.userService.sendAction("save");
        this.personService.sendAction("save");
        this.getItem();
    }

    reciverFeedbackService() {

        console.log("reciverFeedbackService");

        this.userService.reciverFeedbackChanged.subscribe((data: any) => {
            if (data.action == 'cancel'){
                console.log(data);
            }
            else if (data.action == 'save'){
                console.log(data);
            }
          });
    }

    cancel() {

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_cancel')+' ?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.getItem();
            }
      });
    }



    savePic()
    {
        if (this.item.id) {
            this.userService.uploadItem(this.fileList, this.item.id)
            .subscribe(
                {
                    next: () =>
                    {
                        this.item.pictureUri = this.imageUrl;
                        this.hasPicture = true;
                        this.userPicDialog = false;
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.picture_updated'));
                    },
                    error: (err) => {
                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.errorMessage);
                        console.log(`UserProfile - savePic : ${err}.`)
                    }
                });
        }
        else {
            this.item.pictureUri = this.imageUrl;
            this.hasPicture = true;
            this.userPicDialog = false;
        }
    }


    close()
    {
        this.feedback();
    }


    reciverFeedbackUserClerk(event)
    {
        this.item.scUserSignatureId = event;
    }

    feedback(){
      //  this.closeEvent.emit(true);
    }

    translate()
    {
        this.language = this.translateConfigService.getLanguage();

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
             await sleep(50);
             const lab0 = this.translateService.instant('label.home') + ' ';
             const lab1 = ' ' + this.translateService.instant('label.myPersonalData');

            this.BREAD_CRUMB_LIST = [
                {label: lab0,routerLink: '/',selected: false},
                {label: lab1,routerLink: '/user/profile',selected: true}
            ]


         })();




    }
}
