import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom, of } from "rxjs";
import { Address, AddressCreatedResponse, AddressResponse, ScAddressList, ScAddressOutput} from "src/app/models/response/sc-address.response";
import { City } from "src/app/models/response/sc-city.response";
import { DropDown, DropDownMeta } from "src/app/shared/model/base-model";
import { environment } from "src/environments/environment";






@Injectable({
    providedIn: 'root'
  })
  export class ScAddressService {

    environmentUrl = '';

    constructor(private http: HttpClient) {
      this.environmentUrl = environment.ApiCore+"/Address/";
     }


    getList() : Observable<ScAddressList>{
        let url = this.environmentUrl+"User/";
        return this.http.get<ScAddressList>(url);
    }

    getItem(id: string) : Observable<ScAddressOutput>{
        let url = this.environmentUrl+id;
        return this.http.get<ScAddressOutput>(url);
    }

    getUserAddress(id: string) : Observable<ScAddressOutput>{
        let url = this.environmentUrl+"user/"+id;
        return this.http.get<ScAddressOutput>(url);
    }


    setMainAddress(item: ScAddressOutput, isClient: boolean) : Observable<ScAddressOutput>{

        let url = this.environmentUrl;
        url = isClient ? url + "User/"+item.id : url+item.id;
        item.isMainAddress=true;
        console.log(url);
        return this.http.put(url, item);
    }

    updUser(item: ScAddressOutput) : Observable<ScAddressOutput>{      
      let url = this.environmentUrl + "User/" + item.id;
      return this.http.put(url, item);
    }

    add(item: ScAddressOutput) : Observable<ScAddressOutput>{
        let url = this.environmentUrl
        return this.http.post(url, item);
    }

    upd(item: ScAddressOutput) : Observable<ScAddressOutput>{
        console.log('upd');
        console.log(item);
        let url = this.environmentUrl+item.id;
        return this.http.put(url, item);
    }



   async findCEP(cep: string) : Promise<Address> {

      cep = cep.replace(/\D/g, '');
      if (cep !== '') {
        const validacep = /^[0-9]{8}$/;
        if (validacep.test(cep)) {
          const addressResponse = await firstValueFrom(this.http.get<AddressResponse>(`${this.environmentUrl}GetAddressByPostalCode/${cep}`));
          return addressResponse.data;
        }
      }
    }

    // async saveAddress(address : Address) : Promise<AddressCreatedResponse> {
    //   return await firstValueFrom(this.http.post<AddressCreatedResponse>(`${this.environmentUrl}/Address`, address));
    // }

  }
