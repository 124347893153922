import { state } from '@angular/animations';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { ConstantsService } from 'src/app/core/services/constants.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { PhoneNumber, ScAddressOutput, ScPerson, ScPersonOutput, ScPersonSpouse } from '../../../../../../models/response/sc-person.model';
import { Table } from 'primeng/table';
import { formatDate } from '@angular/common';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { ActivatedRoute } from '@angular/router';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';
import { ScAddressService } from 'src/app/core/services/sc/sc-address.service';
import { ScCountryOutput } from 'src/app/models/response/sc-country.response';
import { ScCityOutput } from 'src/app/models/response/sc-city.response';
import { ScStateOutput } from 'src/app/models/response/sc-state.response';
import { ScCityService } from 'src/app/core/services/sc/sc-city.service';
import { ScStateService } from 'src/app/core/services/sc/sc-state.service';
import { ScCountryService } from 'src/app/core/services/sc/sc-country.service';

@Component({
  selector: 'app-sc-person-full-grid',
  templateUrl: './sc-person-full-grid.component.html',
  styleUrls: ['./sc-person-full-grid.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ScPersonFullGridComponent implements OnInit, AfterContentChecked {

  public list: ScPerson[] = [];
  public item: ScPersonOutput = {} as ScPersonOutput;

  public logComponent: string = "ScPersonFull";
  public language: string = "pt-BR";
  public flUpdate: boolean = false;
  public dialogHeader: string = "";
  public btnNewLabel: string = "";
  public type: string = "";

  displayDialog: boolean = false;
  public pageMessage: string = "";
  public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
  public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";

  submitted: boolean = false;
  canUpdate: boolean = false;

  dialogAddressVisible: boolean = false;
  dialogPersonAddressVisible: boolean = false;
  public formattedAddress: string = '';
  public zipCodeMask: string = "";
  public zipCode: string = "";
  listAddress: ScAddressOutput[] = [];

  suggestionsPerson: ScPerson[] = [];
  flagPersonType: string = '';
  fieldValidation: boolean = false;

  listCountry: ScCountryOutput[] = [];
  listState: ScStateOutput[] = [];
  listCity: ScCityOutput[] = [];
  public loadListCountry: boolean = false;
  public loadListState: boolean = false;
  public loadListCity: boolean = false;

  public uploadFilesModal: boolean = false;

  constructor(private scPersonService: ScPersonService,
    private translateConfigService: TranslateConfigService,
    private userTokenService: UserTokenService,
    private constantsService: ConstantsService,
    private translateService: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private notificationsService: NotificationsService,
    private addressService: ScAddressService,
    private cityService: ScCityService,
    private stateService: ScStateService,
    private countryService: ScCountryService,
    private activateRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.language = this.translateConfigService.getLanguage();
    this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'SC_MANAGER_U']);
    this.canUpdate = this.flUpdate;
    this.translate();

    this.activateRoute.paramMap.subscribe(params => {
      if (params.has('flagPersonType')) {
          this.flagPersonType = params.get('flagPersonType');
          this.getList();
      }
    });
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  translate() {
    const sleep = (ms) => new Promise(r => setTimeout(r, ms));
    (async () => {
      await sleep(100);
      const labAccessControl = this.translateService.instant('label.accessControl') + ' ';
      const labPage = ' ' + this.translateService.instant('label.person');
      this.breadcrumbService.setItems([{ label: labAccessControl }, { label: labPage, routerLink: ['person-full'] }]);
      this.dialogHeader = this.translateService.instant('title.sc_person_edit');
      this.btnNewLabel = this.translateService.instant('button.typeNew');
    })();
  }

  messageSuccess(message: string) {
    this.messageService.add({ severity: this.constantsService.SEVERITY_SUCCESS, summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000 });
  }

  messageError(message: string) {
    this.messageService.add({ severity: this.constantsService.SEVERITY_WARNING, summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000 });
  }

  getList() {
    this.scPersonService.getListPerson(this.language, '', '', this.flagPersonType).subscribe(
      {
        next: (res) => {
          if (res)
            this.list = res.data;
        },
        error: (err) => {
          console.log(`${this.logComponent} - getList : ${err}.`);
        }
      });
  }

  hideDialog() {
    this.displayDialog = false;
    this.submitted = false;
  }

  clear(table: Table) {
    table.clear();
  }

  addItem() {
    this.item = {} as ScPersonOutput;
    this.item.telephone = "";
    this.item.mobile = "";
    this.item.scPersonScAddress = [];
    this.submitted = true;
    this.displayDialog = true;
    this.listAddress = [];
    this.formattedAddress = '';
    this.getAllCountryLocal();
  }

  updItem(item: ScPersonOutput) {
    this.item = { ...item };
    if (this.item.dateOfBirth !== undefined && this.item.dateOfBirth != null) {
      this.item.dateOfBirth = formatDate(this.item.dateOfBirth, 'yyyy-MM-dd', 'en-Us');
    }
    this.getListAddress();
    this.getAllCountryLocal();
    if (this.item.scCountryId !== undefined && this.item.scCountryId != null && this.item.scCountryId != '') {
      this.getAllUFLocal(this.item.scCountryId);
    }
    if (this.item.scStateId !== undefined && this.item.scStateId != null && this.item.scStateId != '') {
      this.getAllCitiesByUFLocal(this.item.scStateId);
    }
    this.displayDialog = true;
  }

  delItem(item: ScPersonOutput) {
    this.item = { ...item };

    this.confirmationService.confirm({
      message: this.translateService.instant('message.confirm_delete') + ' ' + item.name + '?',
      header: this.translateService.instant('title.confirmation'),
      acceptLabel: this.translateService.instant('button.yes'),
      rejectLabel: this.translateService.instant('button.no'),
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-danger',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.delete()
      }
    });
  }

  delete() {
    this.scPersonService
      .delItemPerson(this.item.id)
      .subscribe(
        {
          next: () => {
            this.messageSuccess('message.record_deleted');
            this.getList();
          },
          error: (err) => {
            this.messageError('api_response.ERROR');
            console.log(`${this.logComponent} - delItem : ${err}.`);
          }
        });
  }

  saveItem(closeDialog: boolean) {

    if (this.item.name.trim()) {
      if (this.item.id) {

        this.scPersonService.updItemPerson(this.item)
            .subscribe(
            {
                next: (result) => {
                    this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                    this.getList();
                    this.getListAddress();
                    if (closeDialog) {
                      this.displayDialog = false;
                    }
                },
                error: (err) => console.log(err)
            });
      } else {

        this.item.isClient = this.flagPersonType == 'client' ? true : false;
        this.item.isSupplier = this.flagPersonType == 'supplier' ? true : false;
        this.item.isEmployee = this.flagPersonType == 'employee' ? true : false;

        this.scPersonService.addItemPerson(this.item)
          .subscribe(
          {
              next: (result) => {
                  this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_inserted'));
                  this.saveItemConfirm();
                  this.getList();
                  this.displayDialog = false;
              },
              error: (err) => console.log(err)
          });
      }
    }
  }

  saveItemConfirm() {

    this.confirmationService.confirm({
      message: this.translateService.instant('label.message_address_inserted') + '?',
      header: this.translateService.instant('title.confirmation'),
      acceptLabel: this.translateService.instant('button.yes'),
      rejectLabel: this.translateService.instant('button.no'),
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-danger',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.showDialogAddress();
      }
    });
  }

  reciverFeedbackPersonSpouse(event) {
    let name = '';
    let ssn = '';
    if (event.name.split('-').length > 1) {
      ssn = event.name.split('-')[0];
      name = event.name.split('-')[1];
    }
    //this.item.scPersonSpouse = { name: name, scPersonId: event.id, ssn: ssn };
    this.item.spouseId = event.id;
  }

  reciverFeedbackPersonGender(event) {
    this.item.scPersonGenderId = event;
  }

  reciverFeedbackPersonMaritalStatus(event) {
    this.item.scPersonMaritalStatusId = event.id;
  }

  reciverFeedbackPersonTypeNationality(event) {
    this.item.scPersonTypeNationalityId = event;
  }

  reciverFeedbackPersonMatrimonialProperty(event) {
    this.item.scPersonMatrimonialPropertyId = event;
  }

  addressSubmitted(addressId) {
    this.dialogAddressVisible = false;

    if (addressId == null || addressId == "" || addressId == undefined) {
        this.item.addressId = null;
        //this.companyForm.controls['addressId'].setValue(null);
    }
    else {
        //this.item.addressId = addressId;
        if (this.item.name && this.item.name.trim()) {
          if (this.item.id) {
            this.scPersonService.addItemAddress({scAddressId: addressId, scPersonId: this.item.id})
              .subscribe(
                {
                    next: (result) => {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_inserted'));
                        this.dialogAddressVisible = false;
                        this.getListAddress();
                    },
                    error: (err) => console.log(err)
                });
          }
        } else {
          this.item.scPersonScAddress.push({scAddressId: addressId, scPersonId: '00000000-0000-0000-0000-000000000000'});
          this.dialogAddressVisible = false;
        }
    }
  }

  showDialogAddress() {
    this.dialogAddressVisible = true;
  }

  formattedAddressSubmitted(formattedAddress) {
    //this.formattedAddress = formattedAddress;
  }

  showDialogNewAddress() {

    this.dialogPersonAddressVisible = true;
    const language = this.translateService.currentLang;
    if (language == "pt-BR") {
      this.zipCodeMask = "99999-999"
    }
    else if (language == "en-US") {
      this.zipCodeMask = "99999"
    }
  }

  async getAddressCodePostal() {
    // Nova variável "cep" somente com dígitos.
    console.log(this.zipCode);
    this.zipCode = this.zipCode.replace(/\D/g, '');

    if (this.zipCode != null && this.zipCode !== '') {
      try {
        const value = await this.addressService.findCEP(this.zipCode);
      } catch (error) {
        console.log(error);
      }
    }
  }

  getListAddress() {
    this.listAddress = [];
    this.formattedAddress = '';
    this.scPersonService.getListAddress(this.item.id).subscribe(
      {
        next: (res) => {
          if (res) {
            res.data.forEach(r => {
              if (this.item.addressId == r.id) {
                r.checked = this.item.addressId == r.id ? true : false;
                this.formattedAddress = r.route + ' ,' + (r.streetNumber == null ? ' SN' : r.streetNumber) + ' - ' + (r.streetComplement == null ? '' : r.streetComplement) + ' - ' + r.neighborhood + ' - ' + r.city + '/' + r.state + ' - ' + r.postalCode;
              }
            });
            this.listAddress = res.data;
          }
        },
        error: (err) => {
          console.log(`${this.logComponent} - getListAddress : ${err}.`);
        }
      });
  }

  delItemAddress(address: any) {

    this.confirmationService.confirm({
      message: this.translateService.instant('message.confirm_delete') + ' ' + address.route + '?',
      header: this.translateService.instant('title.confirmation'),
      acceptLabel: this.translateService.instant('button.yes'),
      rejectLabel: this.translateService.instant('button.no'),
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-danger',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteAddress(address.id);
      }
    });
  }

  deleteAddress(scAddressId: string) {
    if (this.item.id) {
      this.scPersonService
        .delItemAddress(scAddressId, this.item.id)
        .subscribe(
          {
            next: () => {
              this.messageSuccess('message.record_deleted');
              this.getListAddress();
            },
            error: (err) => {
              this.messageError('api_response.ERROR');
              console.log(`${this.logComponent} - delItemAddress : ${err}.`);
            }
          });
    } else {

    }
  }

  updItemAddress(address: any) {
    if (address.checked) {
      this.item.addressId = address.id;
    } else {
      this.item.addressId = '';
    }
    this.saveItem(false);
    this.dialogPersonAddressVisible = false;
  }

  getPersonSearch(cpf: string, name: string) {
    this.scPersonService.getListPerson(this.language, cpf, name, null).subscribe(
      {
        next: (res) => {
          if (res) {
            this.suggestionsPerson = res.data;
            this.suggestionsPerson = this.suggestionsPerson.filter(f => !this.list.map(m => m.id).includes(f.id));
          }
        },
        error: (err) => {
          console.log(`${this.logComponent} - getPersonSearch : ${err}.`);
        }
      });
  }

  getPersonSearchByCpf(cpf: string) {
    this.fieldValidation = true;
    this.item = {} as ScPersonOutput;
    this.item.cpf = cpf;
    this.item.telephone = "";
    this.item.mobile  = "";
    this.scPersonService.getListPerson(this.language, cpf, '', null).subscribe(
      {
        next: (res) => {
          if (res && res.data.length > 0) {
            this.item = res.data[0];
            this.item.isClient = this.item.isClient == true || this.flagPersonType == 'client' ? true : false;
            this.item.isSupplier = this.item.isSupplier == true || this.flagPersonType == 'supplier' ? true : false;
            this.item.isEmployee = this.item.isEmployee == true || this.flagPersonType == 'employee' ? true : false;
            this.getListAddress();
          } else {

          }
        },
        error: (err) => {
          console.log(`${this.logComponent} - getPersonSearchByCpf : ${err}.`);
        }
      });
  }

  searchPerson(event) {
    if (!isNaN(event.query)) {
      if (event.query.length < 11) {
        this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.translateService.instant('label.ssn_invalid'));
      } else {
        this.getPersonSearch(event.query, '');
      }
    } else {
      this.getPersonSearch('', event.query);
    }
  }

  onSelectPerson(event) {

    this.confirmationService.confirm({
      message: this.translateService.instant('message.confirm_update') + ' ' + event.name + '?',
      header: this.translateService.instant('title.confirmation'),
      acceptLabel: this.translateService.instant('button.yes'),
      rejectLabel: this.translateService.instant('button.no'),
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-danger',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.item = event;
        this.item.isClient = this.item.isClient == true || this.flagPersonType == 'client' ? true : false;
        this.item.isSupplier = this.item.isSupplier == true || this.flagPersonType == 'supplier' ? true : false;
        this.item.isEmployee = this.item.isEmployee == true || this.flagPersonType == 'employee' ? true : false;
        this.saveItem(true);
      }
    });
  }

  getFieldValidation(value: boolean) {
    this.fieldValidation = value;
    return this.fieldValidation;
  }

  getAllFieldValidation(): boolean {
    if (!this.item.cpf || !this.item.name || !this.item.email || !this.item.scPersonMaritalStatusId || !this.item.motherName || !this.item.fatherName
      || !this.item.dateOfBirth || !this.item.scPersonGenderId || !this.item.scCountryId || !this.item.scStateId || !this.item.scCityId) {
      return true;
    } else {
      return false;
    }
  }

  onCompleteCpf() {
    this.getPersonSearchByCpf(this.item.cpf.replace(/\D/g, ''));
  }

  getAllCountryLocal() {
    this.loadListCountry = false;
    this.listCountry = [];
    this.countryService.getList().subscribe(
      {
        next: (res) => {
          if (res && res.data.length > 0) {
            this.listCountry = res.data.filter(f => f.name.toLowerCase() != 'brasil');

            if (res.data.filter(f => f.name.toLowerCase() == 'brasil').shift()) {
              let countryDefault = res.data.filter(f => f.name.toLowerCase() == 'brasil').shift();
              this.listCountry.unshift(countryDefault);
            }
          }
          this.loadListCountry = true;
        },
        error: (err) => {
          console.log(`${this.logComponent} - getAllCountryLocal : ${err}.`);
          this.loadListCountry = true;
        }
      });
  }

  getAllUFLocal(countryId: string) {
    this.listState = [];
    this.stateService.getDropDownList(countryId).subscribe(
      {
        next: (res) => {
          if (res && res.data.length > 0) {
            this.listState = res.data;
          }
          this.loadListState = true;
        },
        error: (err) => {
          console.log(`${this.logComponent} - getAllUFLocal : ${err}.`);
          this.loadListState = true;
        }
      });
  }

  getAllCitiesByUFLocal(stateId: string) {
    this.listCity = [];
    this.cityService.getDropDownList(stateId).subscribe(
      {
        next: (res) => {
          if (res && res.data.length > 0) {
            this.listCity = res.data;
          }
          this.loadListCity = true;
        },
        error: (err) => {
          console.log(`${this.logComponent} - getAllCitiesByUFLocal : ${err}.`);
          this.loadListCity = true;
        }
      });
  }

  reciverFeedbackCountry(countryId: string) {
    this.item.scCountryId = countryId;
    this.getAllUFLocal(countryId);
  }

  reciverFeedbackState(stateId: string) {
    this.item.scStateId = stateId;
    this.getAllCitiesByUFLocal(stateId);
  }

  reciverFeedbackCity(cityId: string) {
    this.item.scCityId = cityId;
  }

  uploadFiles() {
    this.uploadFilesModal = true;
  }
}
