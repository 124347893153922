import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { WoOrderSortingService } from 'src/app/core/services/wo-order/wo-order-sorting.service';
import { BreadCrumb } from 'src/app/shared/components/sf-breadcrumb/sf-breadcrumb.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDown } from 'src/app/shared/model/base-model';
import { ScUserService } from 'src/app/core/services/sc/sc-user.service';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';
import { WoOrderServiceService } from 'src/app/pages/wo-order-service/wo-order-service/wo-order-service.service';
import { WoOrderOrderServiceList } from 'src/app/pages/wo-order-service/wo-order-service/wo-order-service.model';
import { WoWorkFlowService } from 'src/app/pages/wo-workflow/wo-workflow.service';
import { WoWorkFlowTaskOutput } from 'src/app/pages/wo-workflow/wo-workflow.model';
import { WoOrderService } from 'src/app/pages/wo-order/wo-order.service';
import { WoOrderOutput } from 'src/app/pages/wo-order/wo-order.model';
import { SfMessageService } from 'src/app/core/services/core/sf-message.service';
import { ScUserMessageOutput } from 'src/app/models/response/sc-user.model';


@Component({
    selector: 'wo-order-client-detail',
    templateUrl: './wo-order-client-detail.component.html',
    styleUrls: ['./wo-order-client-detail.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class WoOrderClientDetailComponent implements OnInit {

    public id:string;
    public item: WoOrderOutput = {};
    public woOrderOrderServiceList: WoOrderOrderServiceList = {} as WoOrderOrderServiceList;

    public scUserId: string = '';
    public scPersonId: string = '';
    public isClient: boolean = true;

    public language: string = 'pt-BR';
    public displayMsg: boolean = false;
    public displayDialog: boolean;
    public submitted: boolean;

    public BREAD_CRUMB_LIST: Array<BreadCrumb> = [];


    public listWorkflowTask: WoWorkFlowTaskOutput[] = [];
    public showBtn: boolean = false;
    public message: string = '';

    itemOrder: WoOrderOutput = {} as WoOrderOutput;

    userMessagenList: ScUserMessageOutput[] = [];

    constructor(
        private userTokenService: UserTokenService,
        private translateService: TranslateService,
        private translateConfigService: TranslateConfigService,
        private orderSortingService: WoOrderSortingService,
        private router: Router,
        private confirmationService: ConfirmationService,
        private activateRoute: ActivatedRoute,
        private personService: ScPersonService,
        private woOrderService: WoOrderService,
        private woOrderServiceService: WoOrderServiceService,
        private workFlowService: WoWorkFlowService,

        private messageService: SfMessageService,
        private userService: ScUserService
    ) { }

    ngOnInit(): void {

        this.scUserId = this.userTokenService.getUserId();
        this.scPersonId = this.userTokenService.getPersonId();


        this.activateRoute.paramMap.subscribe(params => {
            if (params.has('id')) {
                this.id = params.get('id');
                this.onTranslate();
                this.onGetItem();
                this.getListUserMessage();
            }
        });
    }


    onGetItem() {

        this.orderSortingService.getItem(this.id).subscribe(
            {
                next: (res) => {
                    if (res) {
                        this.item = res.data;

                        //check if client own the order (client is the person who created the order)
                        if (this.isClient && this.scPersonId != this.item.scClientId )
                        {
                            this.router.navigate(['order-client']);
                        }

                        //check if is Order's Attendent
                        if (this.scUserId == this.item.scUserId || this.scPersonId == this.item.scClientId) {
                            this.showBtn = true;
                        }


                        this.onGetPersonById();
                        this.onGetOrderServiceSummary(this.item.id);
                        this.onGetStatusNext();
                }
                },
                error: (err) => {
                    console.log(`WoOrderClientDetailComponent - getItem : ${err}.`);
                    console.log(err);
                }
            });
    }


    onGetOrderServiceSummary(woOrderId: string) {
        this.woOrderServiceService.getOrderOrderServiceSummaryClientList(woOrderId).subscribe(
            {
                next: (res) => {
                    if (res) {
                        this.woOrderOrderServiceList = res;
                    }
                },
                error: (err) => {
                    console.log(`${'WoOrderClientDetailComponent'} - getOrderServiceSummary : ${err.messageError}.`);
                }
            });
    }



    onGetStatusNext() {
        this.workFlowService.getTaskStatusList(this.item.woWorkFlowId, this.item.woOrderStatusId, this.language, this.item.id).subscribe(
            {
                next: (res) => {
                    if (res) {
                        if (res.data != null) {
                            this.listWorkflowTask = res.data.items;
                        }
                    }

                },
                error: (err) => {
                    console.log(`WoOrderDetailComponent - getServiceStatusTimeline : ${err.messageError}.`);
                }
            });
    }


    onGetPersonById(): void {
        this.personService.getById(this.item.scClientId).subscribe({
            next: (res) => {
                if (res) {
                                        let item = res;
                                        console.log(item)
                                        this.personService.sendData(item.data);
                                    }
            },
            error: (err) => console.log(`WoOrderDetailComponent - onGetPersonById : ${err}.`)
        });
    }


    onSaveObs(value: string) {
        this.itemOrder.obs = value;
        this.woOrderService
            .updItem(this.itemOrder)
            .subscribe(
                {
                    next: () => {
                        this.messageService.messageSuccess(this.translateService.instant('message.record_updated'));
                    },
                    error: (err) => {
                        console.log(`WorkFlowDetail - saveObs - Upd : ${err}.`)
                        this.message = this.translateService.instant('api_response.' + err.error);

                        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                            this.message = this.translateService.instant('api_response.ERROR');

                        this.messageService.messageError(this.message);
                    }
                });

    }


    onUpdStatus(idStatus: string) {

        this.woOrderService
            .updStatus(this.item.id, idStatus, "")
            .subscribe(
                {
                    next: () => {
                        this.messageService.messageSuccess(this.translateService.instant('message.record_updated'));
                        this.onGetItem();

                    },
                    error: (err) => {
                        console.log(`WorkFlowDetail - updStatus`)
                        console.log(err)
                        this.message = this.translateService.instant('api_response.' + err.error);

                        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                            this.message = this.translateService.instant('api_response.ERROR');

                        this.messageService.messageError(this.message);
                    }
                });

    }


    onCloseCancel() {
        if (this.isClient)
            this.router.navigate(['order-client']);
        else
            this.router.navigate(['/order-service-cartorio/ded01c2b-5021-4716-9c14-f38701f48271']);
    }


    letters = '0123456789ABCDEF';
    color!: string;
    getRandomColor() {
        this.color = '#';
        for (var i = 0; i < 6; i++) {
            this.color += this.letters[Math.floor(Math.random() * 16)];
        }
    }


    onTranslate() {
        this.language = this.translateConfigService.getLanguage();
        this.isClient = this.userTokenService.hasPermissionArray(['ROLE_CLIENT']);

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
            await sleep(50);
            const lab0 = this.translateService.instant('label.home') + ' ';
            const lab1 = ' ' + this.translateService.instant('label.myServices');
            const lab2 = ' Pedido #' + this.item.code;


            this.BREAD_CRUMB_LIST = [
                { label: lab0, routerLink: '/', selected: false },
                { label: lab1, routerLink: '/order-service-cartorio/ded01c2b-5021-4716-9c14-f38701f48271', selected: false },
                { label: lab2, routerLink: '/order-detail/' + this.item.id, selected: true }
            ]

            if (this.isClient)
                this.BREAD_CRUMB_LIST = [
                    { label: lab0, routerLink: '/', selected: false },
                    { label: lab1, routerLink: 'order-client', selected: false },
                    { label: lab2, routerLink: '/order-detail/' + this.item.id, selected: true }
                ]


        })();


    }

    save(){
        this.personService.sendAction("save");
        this.onGetItem();
    }

    cancel() {

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_cancel')+' ?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.onGetItem();
            }
      });
    }

    getListUserMessage() {
        let param = '?search=WoOrderId = ' + this.id;
        this.userService.getListUserMessage(param).subscribe(
            {
                next: (res) => {
                    if (res) {
                        this.userMessagenList = res.data;

                        this.userMessagenList.forEach((item) => {
                            if (item.color == undefined || item.color == null || item.color == '') {
                                let color = '#';
                                for (var i = 0; i < 6; i++) {
                                    if (i == 0 && this.letters[Math.floor(Math.random() * 16)] != 'F') {
                                        i = -1;
                                        continue;
                                    }

                                    color += this.letters[Math.floor(Math.random() * 16)];
                                }

                                this.userMessagenList.filter(f => f.sourceUserName == item.sourceUserName).forEach((item) => {
                                    item.color = color;
                                });
                            }
                        });
                        this.userMessagenList.sort((a, b) => Date.parse(a.dateOfRegistration?.toString()) - Date.parse(b.dateOfRegistration?.toString()));
                    }
                },
                error: (err) => console.log(`WoOrderClientDetailComponent - getListUserMessage : ${err}.`)
            });
    }

}
