<div class="chat-container">
    <div class="messages">
        <div *ngFor="let message of messages" class="message">
            <strong>{{ message.user }}:</strong> {{ message.text }}
        </div>
    </div>
    <form (ngSubmit)="sendMessage()">
        <input [(ngModel)]="newMessage" name="message" required placeholder="Digite sua mensagem aqui..." />
        <button type="submit">Enviar</button>
    </form>
</div>