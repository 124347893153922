
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { SfcDropDownClerkComponent } from './sfc-dropdown-clerk.component';
import { ScUserService } from 'src/app/core/services/sc/sc-user.service';


@NgModule({
    declarations: [SfcDropDownClerkComponent],
    exports: [SfcDropDownClerkComponent],
    imports: [
        CommonModule,
        DropdownModule,
        SfDropDownModule

    ],
    providers: [ScUserService]
})
export class SfcDropDownClerkModule { }
