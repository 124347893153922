import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserToken } from 'src/app/core/auth.model';
//import { UserTokenService } from 'src/app/core/services/user-token.service';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../../app.main.component';
import { UserTokenService } from 'src/app/core/services/user-token.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    user$: Observable<UserToken>;
    public logo: String = "";
    public hasLogo: boolean = false;
    public idUsuario: number;
    public companyName: String = "";
    currentUser!: UserToken;
    userAcronym!: string;
    readonly AVATAR_COLOR = '#ba40ef';

    isDesktop$!: Observable<boolean>;

    constructor(public app: AppComponent,
                public appMain: AppMainComponent,
                private router:Router,
                private userTokenService : UserTokenService) {}

    ngOnInit(): void {
       //TODO - Company Name and Logo - where to show?
       this.user$ = this.userTokenService.getUser();
       this.companyName = this.userTokenService.getCompanyName();
       //this.isDesktop$ = this.uiService.onDetectDevice(900);

       this.logo = this.userTokenService.getLogoUri();
        if (this.logo != "" || this.logo.length > 1)
            this.hasLogo = true;

    }

    onGetUser(): void {
        this.userTokenService.getUser().subscribe({
          next: (response) => {
            if (response) {
              this.currentUser = response;

              const fullName = response.Given_name.split(' ')

              const firstName = fullName[0];
              const lastName = fullName[fullName.length - 1];

              const firstletter = firstName[0].toLocaleUpperCase();
              const lastletter = lastName[0].toLocaleUpperCase();

              this.userAcronym = `${firstletter}${lastletter}`;
            }
          },
          error: () => null
        });

      }


}
