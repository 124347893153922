import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class SfMessageService {



  constructor(private translateService: TranslateService,
              private notificationsService: NotificationsService,
  ) { }

  messageSuccess(message: string){
    this.notificationsService.toastNotify('success',this.translateService.instant('title.success'), this.translateService.instant(message));
  }

  messageError(message: string){
    this.notificationsService.toastNotify('error',this.translateService.instant('title.error'), this.translateService.instant(message));
  }

  messageWarning(message: string){
    this.notificationsService.toastNotify('warn',this.translateService.instant('title.warning'), this.translateService.instant(message));
  }
}
