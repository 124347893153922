import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom, of } from "rxjs";
import { City, ScCityList, ScCityOutput } from "src/app/models/response/sc-city.response";
import { DropDown, DropDownMeta } from "src/app/shared/model/base-model";
import { environment } from "src/environments/environment";






@Injectable({
    providedIn: 'root'
  })
  export class ScCityService {

    environmentUrl = '';

    constructor(private http: HttpClient) {
      this.environmentUrl = environment.ApiCore+"/City/";
     }


    getListByState(stateId: string) : Observable<ScCityList>{
        let url = this.environmentUrl+"?search="+stateId;
        return this.http.get<ScCityList>(url);
    }

    getListByCountry(countryId: string) : Observable<ScCityList>{
        let url = this.environmentUrl+"?search=ScCountryId="+countryId;
        return this.http.get<ScCityList>(url);
    }

    getItem(id: string) : Observable<ScCityOutput>{
        let url = this.environmentUrl+id;
        return this.http.get<ScCityOutput>(url);
    }


    add(item: ScCityOutput) : Observable<ScCityOutput>{
        let url = this.environmentUrl
        return this.http.put(url, item);
    }

    Upd(item: ScCityOutput) : Observable<ScCityOutput>{
        let url = this.environmentUrl+item.id;
        return this.http.post(url, item);
    }

    getDropDownList(stateId : string): Observable<DropDownMeta>{
        let url = this.environmentUrl + "DropDown?search=" + stateId;
        return this.http.get<DropDownMeta>(url);
    }

    getBrazilianCitiesByUF(uf : String): Observable<City[]>{
        if(uf != '')
          return this.http.get<City[]>(`https://brasilapi.com.br/api/ibge/municipios/v1/${uf}?providers=dados-abertos-br,gov,wikipedia`)
    }


  }
