<sf-loader></sf-loader>
<sf-notification></sf-notification>

<div class="formgrid grid">
    <div class="col-12 sm:col-12 md:col-12 lg:col-6">
        <sf-sub-header
            [title]="'label.myAddresses' | translate"
            [description]="'label.myAddressesDescription' | translate">
        </sf-sub-header>
    </div>
    <div class="col-12 sm:col-12 md:col-12 lg:col-6">
        <sf-breadcrumb [model]="BREAD_CRUMB_LIST"></sf-breadcrumb>
    </div>
</div>

<p-divider></p-divider>

<p-toolbar class="mb-3">
    <div class="p-toolbar-group-left">
        <p-button
            icon="pi pi-plus"
            styleClass= "p-button p-button-success p-m-1 mr-1 text-lg font-semibold"
            [label]="'button.addressNew' | translate"
            [pTooltip]="'button.addressNew' | translate"
            (click)="addItem()">
        </p-button>
    </div>

    <div class="p-toolbar-group-right">
        <p-button icon="pi pi-refresh"
            styleClass= "p-button p-button-primary p-m-1 mr-1 text-lg font-semibold"
            (click)="getList()">
        </p-button>
    </div>
</p-toolbar>

<div class="formgrid grid">
    <div class="m-2">
        <p-dataView #dv [value]="list">
            <ng-template let-item pTemplate="listItem">

                    <div class="field col-12 sm:col-12 md:col-12 lg:col-1 order-1 text-center">
                        <img *ngIf="item.reference == 'HOME' || item.reference == null" src="assets/layout/images/map_home.png" alt="Image" width="64"/>
                        <img *ngIf="item.reference == 'WORK'" src="assets/layout/images/map_work.png" alt="Image" width="64"/>

                        <div style="display: block;" class="mt-3 mb-3" >
                            <span class="text-xl font-semibold text-surface-900 dark:text-surface-0">
                                <span *ngIf="item.reference == 'HOME' || item.reference == null" translate>label.addressHome</span>
                                <span *ngIf="item.reference == 'WORK'" translate>label.addressWork</span>
                            </span>
                        </div>
                    </div>

                    <div class="field col-12 sm:col-12 md:col-12 lg:col-8 order-3">
                        <div style="display: block;" class="text-xl font-medium text-surface-900 dark:text-surface-0 mt-2">
                            {{ item.formattedAddress }}
                        </div>
                        <div style="display: block;" class="mt-3 mb-3" >
                            <span class="text-lg">
                                {{item.city}} / {{item.state}} - {{item.postalCode}}
                            </span>
                        </div>
                        <div style="display: block;" class="mt-3 mb-3" >
                            <p-button *ngIf="item.isMainAddress == true"
                                icon="pi pi-check"
                                styleClass= "p-button p-button-help p-m-1 mr-1 text-lg font-semibold"

                                [label]="'label.addressMain' | translate">
                            </p-button>
                            <p-button *ngIf="item.isMainAddress == false"
                                icon="pi pi-check"
                                styleClass= "p-button p-button-help p-button-outlined p-m-1 mr-1 text-lg font-semibold"

                                [label]="'label.addressSetMain' | translate"
                                (click)="setMainAddress(item)">
                            </p-button>
                        </div>



                    </div>

                    <div class="field col-12 sm:col-12 md:col-12 lg:col-3 order-2">
                        <div class="flex flex-col md:items-end gap-8 mt-4">
                            <div class="flex flex-row-reverse md:flex-row gap-2">
                                <p-button
                                    icon="pi pi-pencil"
                                    [styleClass]="classBtnUpd"
                                    [label]="'button.addressUpd' | translate"
                                    [pTooltip]="'button.edit' | translate"
                                    (click)="updItem(item)">
                                </p-button>
                                <p-button icon="pi pi-times"
                                    [styleClass]="classBtnDel"
                                    [label]="'button.addressDel' | translate"
                                    [pTooltip]="'button.delete' | translate"
                                    (click)="delItem(item)">
                                </p-button>
                            </div>
                        </div>
                    </div>

            </ng-template>
        </p-dataView>
    </div>
</div>

<p-dialog *ngIf="showDialog" [(visible)]="showDialog" [style]="{width: '80vw', height:'70vh'}">
    <p-header> {{ 'label.address' | translate }} </p-header>
        <sf-address-detail (formattedAddressEmitter)="formattedAddressSubmitted($event)" (newAddressEmitter)="addressSubmitted($event)" [addressId]="item.id" [item]="item"></sf-address-detail>
</p-dialog>


