

import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { InputMaskModule } from 'primeng/inputmask';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { AuthService } from 'src/app/core/auth/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';


import { LoaderModule } from 'src/app/shared/components/loader/loader.module';

import { CheckboxModule } from 'primeng/checkbox';
import { TabPanel, TabViewModule } from 'primeng/tabview';

import { TimelineModule } from 'primeng/timeline';

import { ImageCropperModule } from 'ngx-image-cropper';

import localePT from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';

import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { SfcDropDownClerkModule } from 'src/app/modules/cartorio/component/sfc-dropdown-clerk.component/sfc-dropdown-clerk.module';
import { ScUserGridComponent } from './sc-user-grid/sc-user-grid.component';
import { ScUserProfileComponent } from './sc-user-profile/sc-user-profile.component';
import { ScUserDetailComponent } from './sc-user-detail/sc-user-detail.component';
import { ScOrganizationStructureModule } from 'src/app/pages/sc-organization-structure/sc-organization-structure.module';
import { WoSkillModule } from 'src/app/pages/wo-skill/wo-skill.module';
import { ScUserService } from 'src/app/core/services/sc/sc-user.service';
import { ScRoleModule } from 'src/app/pages/sc-role/sc-role.module';

import { SfSubHeaderModule } from 'src/app/shared/components/sub-header/sub-header.module';
import { SfBreadCrumbModule } from 'src/app/shared/components/sf-breadcrumb/sf-breadcrumb.module';
import { DividerModule } from 'primeng/divider';
import { ScUserRoutingModule } from './sc-user.routing.module';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SharedModule } from 'src/app/shared/shared.module';
import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { SCPersonModule } from '../sc-person/sc-person.module';




registerLocaleData(localePT, 'pt', localeExtraPT);

@NgModule({
    declarations: [ScUserGridComponent, ScUserDetailComponent, ScUserProfileComponent],
    exports: [ScUserGridComponent, ScUserDetailComponent, ScUserProfileComponent],
    imports: [
        FormsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        ButtonModule,
        InputTextModule,
        InputMaskModule,
        CheckboxModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        TableModule,
        TooltipModule,
        ConfirmDialogModule,
        LoaderModule,
        CardModule,
        DialogModule,
        NotificationsModule,
        DropdownModule,
        InputNumberModule,
        CalendarModule,
        InputTextModule,
        ScrollPanelModule,
        TimelineModule,
        DividerModule,
        SharedModule,
        SfBreadCrumbModule,
        SfSubHeaderModule,
        SfDropDownModule,
        ScUserRoutingModule,
        WoSkillModule,
        ScOrganizationStructureModule,
        ScRoleModule,
        TabViewModule,
        SCPersonModule



        ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, ScUserService, {provide: LOCALE_ID, useValue: 'pt'}]
})
export class ScUserModule { }
