
<sf-loader></sf-loader>
<sf-notification></sf-notification>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="formgrid grid">
    <div class="col-12 sm:col-12 md:col-6 lg:col-4">
        <sf-sub-header [title]="'Detalhe do Atendimento'"
            [description]="'Atendimento Cliente-OnLine'"></sf-sub-header>
    </div>
    <div class="col-12 sm:col-12 md:col-6 lg:col-4">
        <div class="text-2xl text-center font-semibold text-surface-900 dark:text-surface-0">
            {{item.companyName}}
        </div>
        <div class="text-2xl text-center font-semibold text-surface-900 dark:text-surface-0">
            Pedido #{{item.code}}
            <span [style]="{'background-color':''+item.stageColor+'', 'color': '#FFFFFF'}"
                [class]="'status-badge-small'">
                {{item.statusName}}
            </span>
        </div>
    </div>
    <div class="col-12 sm:col-12 md:col-12 lg:col-4">
        <sf-breadcrumb [model]="BREAD_CRUMB_LIST"></sf-breadcrumb>
    </div>
</div>



<p-divider></p-divider>


<div class="p-col-12" style="margin-top: 15px;">
    <div class="formgrid grid">
        <div class="field col-12 md:col-3 lg:col-3 xl:col-3" style="height: 638px; overflow-y: auto;">
            <label class="font-bold text-2xl w-full">Dados do Pedido</label>
            <p-card>
                <div class="p-grid">
                    <div class="formgrid grid text-lg">
                        <div class="field col-12">
                            <label class="font-bold text-xl">Cliente:</label>
                            <label class="text-xl ml-1">{{item.clientName}}</label>
                        </div>
                        <div class="field col-12">
                            <label class="font-bold text-xl">Contato Cliente:</label>
                            <label class="text-xl ml-1">{{item.clientTelephone}} / {{item.clientMobile}}</label>
                        </div>
                        <div class="field col-12">
                            <label class="font-bold text-xl">Atendente:</label>
                            <label class="text-xl ml-1">{{item.userName}}</label>
                        </div>
                        <div class="field col-12">
                            <label class="font-bold text-xl">Data Abertura:</label>
                            <label class="text-xl ml-1">{{item.createdDate | date: 'dd/MM/yyyy HH:mm'}}</label>
                        </div>
                        <div class="field col-12">
                            <label for="observacao" class="font-bold text-xl font-bold">Observações</label>
                            <div class="p-inputgroup">
                                <textarea pInputTextarea
                                    id="observacao"
                                    class="w-full text-xl"
                                    rows="3"
                                    cols="30"
                                    [(ngModel)]="item.obs"
                                    placeholder="Adicione as observações">
                                </textarea>
                                <div class="ml-2 mt-2">
                                    <button pButton pRipple
                                        icon="pi pi-save"
                                        class="p-button p-button-success p-mr-2 mt-3 font-semibold"
                                        (click)="onSaveObs(item.obs);">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-card>
            <div class="mt-3"></div>
            <label class="font-bold text-2xl w-full">Itens do Pedido</label>
            <p-card>
                <div class="p-grid">
                    <div class="formgrid grid text-lg">
                        <div class="field col-12">
                            <div class="card flex justify-content-center">
                                <p-listbox
                                    class="w-full"
                                    [options]="woOrderOrderServiceList.data"
                                    optionLabel="name"
                                    [listStyle]="{ 'max-height': '250px' }"
                                    [listStyle]="{'max-height': '220px'}">
                                    <ng-template let-service pTemplate="item">
                                        <div class="flex align-items-center gap-2">
                                            <div style=" background-color:orange;">
                                                <img
                                                    [src]="service.icon === undefined || service.icon == null
                                                        ? '../../../assets/layout/images/sf-logoTabellio.png'
                                                        : '../../../../../assets/images/images-order/' + service.icon + ''"
                                                    class="new-order"
                                                    width="30"
                                                    height="30">
                                            </div>
                                            <div class="text-lg">{{ service.name ?? 'Itens não cadastrados' }}</div>
                                        </div>
                                    </ng-template>
                                </p-listbox>
                            </div>
                        </div>
                    </div>

                </div>
            </p-card>
        </div>
        <div class="field col-12 md:col-6 lg:col-6 xl:col-6">
            <label class="font-bold text-2xl w-full">Detalhes do Pedido</label>

            <p-card>
                <div style="height: 580px; overflow-y: auto;">
                    <p-tabView styleClass="tabview-custom">
                        <p-tabPanel *ngIf="!isClient">
                            <ng-template pTemplate="header">
                                <div class="flex align-items-center gap-2">
                                    <i class="pi pi-user ml-5" style="color: rgb(104,159,76); font-size: 2.0rem"></i>
                                    <span class="font-bold text-xl ml-2 mb-2">
                                        Dados do Cliente
                                    </span>
                                </div>
                            </ng-template>



                            <ng-container>
                                <div class="grid">
                                    <div class="col">
                                        <div class="text-right">
                                            <button pButton pRipple icon="pi pi-times" style="width: 120px; margin: 3px;" class="p-button-danger p-mr-2 font-medium text-xl" [label]="'button.cancel' | translate" (click)="cancel();" ></button>
                                            <button pButton pRipple icon="pi pi-save" style="width: 120px; margin: 3px;" class="p-button-success p-mr-2 font-medium text-xl" [label]="'button.save' | translate" (click)="save();" ></button>
                                        </div>
                                    </div>
                                </div>
                                <sc-person-full-client [id]=""></sc-person-full-client>
                            </ng-container>
                            <!-- <ng-container >
                                <span class="container__list--loader">Carregando...</span>
                            </ng-container> -->

                            <!--DADOS CLIENTE FIM-->

                        </p-tabPanel>
                        <p-tabPanel header="Documentação">
                            <ng-template pTemplate="header">
                                <div class="flex align-items-center gap-2">
                                    <div class="flex align-items-center gap-2">
                                        <i class="pi pi-id-card ml-5" style="color: rgb(104,159,76); font-size: 2.0rem"></i>
                                        <span class="font-bold text-xl ml-2 mb-2">
                                            Documentos
                                        </span>
                                    </div>
                                </div>
                            </ng-template>
                            <div class="card" *ngIf="item.scClientId !== undefined">
                                <sf-sc-person-file [scPersonId]="item.scClientId" [scPersonName]="item.clientName"></sf-sc-person-file>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="Historico de Mensagens">
                            <ng-template pTemplate="header">
                                <div class="flex align-items-center gap-2">
                                    <div class="flex align-items-center gap-2">
                                        <i class="pi pi-comments ml-5" style="color:  rgb(104,159,76); font-size: 2.0rem"></i>
                                        <span class="font-bold text-xl ml-2 mb-2">
                                            Mensagens
                                        </span>
                                    </div>
                                </div>
                            </ng-template>
                            <!-- <p>
                                At vero eos et accusamus et iusto odio dignissimos...
                            </p> -->

                            <ng-template pTemplate="content">
                                <div style="height: 500px;  overflow-y: scroll;">
                                    <div *ngFor="let item of userMessagenList; let i = index;" class="mb-2"
                                        [style]="{'background': ''+item.color+'', 'border': 'solid #6d6e70', 'border-width': '0 0 0 6px', 'border-radius': '3px', 'padding': '1rem 1.5rem', 'color': '#FFFFFF'}">
                                        <div class="text-left">
                                            <span class="font-bold">De: </span>{{item.sourceUserName}}
                                        </div>
                                        <div class="text-left">
                                            <span class="font-bold">Para: </span>{{item.targetUserName}}
                                        </div>
                                        <div class="text-left">
                                            <span class="font-bold">Mensagem: </span>{{item.message}}
                                        </div>
                                        <div class="text-left">
                                            <span class="font-bold">Data: </span>{{item.dateOfRegistration | date: 'dd/MM/yyyy HH:mm'}}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                        </p-tabPanel>
                    </p-tabView>
                </div>
            </p-card>
        </div>
        <div class="field col-12 md:col-3 lg:col-3 xl:col-3">
            <label class="font-bold text-2xl w-full">Histórico de Eventos</label>
            <p-card>
                <div style="height: 580px; overflow-y: auto;">
                    <!-- <p-tabView styleClass="tabview-custom" [scrollable]="true">
                        <p-tabPanel header="Ações"> -->
                            <p-panel>
                                <ng-template pTemplate="header">
                                    <div class="flex align-items-center gap-2">
                                        <i class="pi pi-arrow-right-arrow-left ml-5" style="color: #F97316; font-size: 2.0rem"></i>
                                        <span class="font-bold text-xl ml-2 mb-2">
                                            Ações & Operações
                                        </span>
                                    </div>
                                </ng-template>

                                <div class="">
                                    <!-- <h5 class="text-center font-bold">Ações </h5> -->
                                    <span class="p-buttonset">
                                        <div *ngIf="showBtn" class="text-center">
                                            <div *ngFor="let task of listWorkflowTask">
                                                <button pButton pRipple
                                                    label='{{task.code}}'
                                                    icon="{{task.icon}}"
                                                    (click)="updStatus(task.woOrderStatusNextId);"
                                                    [style]="{'border-color':''+task.statusStageColor+'','background-color':''+task.statusStageColor+''}"
                                                    class="btn-action"
                                                    [disabled]="!task.isActive"
                                                    pTooltip="{{task.name}}">
                                                </button>

                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <!-- <button pButton pRipple icon="pi pi-times" class="p-button-danger p-mr-2 mt-3 font-medium btn-action"
                                                label="Sair do Pedido" (click)="closeCancel();"></button> -->
                                            <button pButton pRipple
                                                icon="pi pi-times"
                                                class="btn-action p-button-danger"
                                                label="Sair do Pedido"
                                                (click)="onCloseCancel()">
                                            </button>

                                        </div>

                                    </span>
                                </div>
                            </p-panel>
                        <!-- </p-tabPanel>
                    </p-tabView> -->

                    <!-- <p-tabView styleClass="tabview-custom" [scrollable]="true">
                        <p-tabPanel header="Histórico do Atendimento"> -->
                            <div class="mt-2"></div>
                            <p-panel>
                                <ng-template pTemplate="header">
                                    <div class="flex align-items-center gap-2">
                                        <i class="pi pi-list ml-5" style="color: #F97316; font-size: 2.0rem"></i>
                                        <span class="font-bold text-xl ml-2 mb-2">
                                            Histórico do Pedido
                                        </span>
                                    </div>
                                </ng-template>
                                <p-scrollPanel *ngIf="item.id !== undefined" [style]="{width: '100%', height: '100%', 'display': 'flex'}" styleClass="customized-timeline">
                                    <sf-wo-order-status-timeline [woOrderId]="item.id" [languageCode]="language"></sf-wo-order-status-timeline>
                                </p-scrollPanel>
                            </p-panel>
                        <!-- </p-tabPanel>
                    </p-tabView> -->
                </div>
            </p-card>
        </div>
    </div>
</div>


<!-- person old -->

<!-- <div class="formgrid grid">
    <div class="field col-12 sm:col-6 md:col-6 lg:col-6">
        <label class="w-full font-bold" for="ssn">
            <span translate>label.person_ssn</span>
        </label>
        <span id="ssn">{{personOutput.data.cpf}}</span>
    </div>
    <div class="field col-12 sm:col-6 md:col-6 lg:col-6">
        <div class="text-left">
            <p-rating [readonly]="true" [cancel]="false"
                [(ngModel)]="item.clientRating">
            </p-rating>
        </div>
    </div>
</div>

<div class="formgrid grid">
    <div class="field col-12 sm:col-12 md:col-6 lg:col-6">
        <label class="w-full font-bold" for="name">
            <span translate>label.name</span>
        </label>
        <span id="name">{{personOutput.data.name}}</span>
    </div>
    <div class="field col-12 sm:col-12 md:col-6 lg:col-6">
        <label class="w-full font-bold" htmlFor="email">
            <span translate>label.email</span>
        </label>
        <span id="email">{{personOutput.data.email}}</span>
    </div>
</div>

<div class="formgrid grid">
    <div class="field col-12 sm:col-12 md:col-6 lg:col-3">
        <label class="w-full font-bold" htmlFor="dateOfBirth">
            <span translate>label.date_of_birth</span>
        </label>
        <span id="dateOfBirth">{{personOutput.data.dateOfBirth | date:
            'dd/MM/yyyy'}}</span>
    </div>
    <div class="field col-12 sm:col-12 md:col-6 lg:col-3">
        <label class="w-full font-bold" htmlFor="scPersonGenderId">
            <span translate>label.gender</span>
        </label>
        <span id="scPersonGenderId"></span>
    </div>
    <div class="field col-12 sm:col-12 md:col-6 lg:col-3">
        <label class="w-full font-bold" for="telephone">
            <span translate>label.phone_number</span>
        </label>
        <span id="ssn">{{personOutput.data.telephone}}</span>
    </div>
    <div class="field col-12 sm:col-12 md:col-6 lg:col-3">
        <div class="w-full font-bold">
            <label class="form__item--label" for="mobile">
                <span translate>label.mobile_number</span>
            </label>
            <span id="mobile">{{personOutput.data.mobile}}</span>
        </div>
    </div>
</div>

<div class="formgrid grid">
    <div class="field col-12 sm:col-12 md:col-6 lg:col-3">
        <label class="w-full font-bold" htmlFor="scPersonTypeNationalityId">
            <span translate>label.type_nationality</span>
        </label>
        <span id="scPersonTypeNationalityId"></span>
    </div>
    <div class="field col-12 sm:col-12 md:col-6 lg:col-3">
        <label class="w-full font-bold" htmlFor="scCountryId"><span
                translate>label.country</span></label>
        <span id="scCountryId"></span>
    </div>
    <div class="field col-12 sm:col-12 md:col-6 lg:col-3">
        <label class="w-full font-bold" htmlFor="scStateId">
            <span translate>label.birth_state</span>
        </label>
        <span id="scStateId"></span>
    </div>
    <div class="col-12 sm:col-12 md:col-6 lg:col-3">
        <label class="w-full font-bold" htmlFor="scCityId">
            <span translate>label.birth_city</span>
        </label>
        <span id="scCityId"></span>
    </div>
</div>

<div class="formgrid grid">
    <div class="field col-12 sm:col-6 md:col-6 lg:col-3">
        <label class="w-full font-bold" htmlFor="scPersonMaritalStatusId">
            <span translate>label.marital_status</span>
        </label>
        <span id="scPersonMaritalStatusId"></span>
    </div>
    <div class="field col-12 sm:col-6 md:col-6 lg:col-6">
        <label class="w-full font-bold" htmlFor="scPersonMatrimonialPropertyId">
            <span translate>label.matrimonial_property</span>
        </label>
        <span id="scPersonMatrimonialPropertyId"></span>
    </div>
    <div class="field col-12 sm:col-12 md:col-12 lg:col-3">
        <label class="w-full font-bold" htmlFor="profession">
            <span translate>label.profession</span>
        </label>
        <span id="profession">{{personOutput.data.profession}}</span>
    </div>
</div>

<div class="formgrid grid">
    <div class="field col-12 sm:col-12 md:col-6 lg:col-6">
        <label class="w-full font-bold" htmlFor="motherName">
            <span translate>label.mother_name</span>
        </label>
        <span id="motherName">{{personOutput.data.motherName}}</span>
    </div>
    <div class="field col-12 sm:col-12 md:col-6 lg:col-6">
        <label class="w-full font-bold" htmlFor="fatherName">
            <span translate>label.father_name</span>
        </label>
        <span id="fatherName">{{personOutput.data.fatherName}}</span>
    </div>
</div>

<div class="formgrid grid">
    <div class="field col-12">
        <label class="w-full font-bold" htmlFor="observation">
            <span translate>label.observation</span>
        </label>
        <span id="observation">{{personOutput.observation}}</span>
    </div>
</div> -->
