<div class="dashboard">
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-4">
            <div class="overview-box monthly-sales">
                <div class="overview-title">SALES</div>
                <i class="pi pi-arrow-circle-up"></i>
                <div class="overview-numbers">
                    <strong>$ 22,650</strong>
                </div>
                <div class="overview-ratios">16% more than previous month</div>
                <img src="assets/layout/images/icon-sales.png" alt="california-layout" />
            </div>
        </div>
        <div class="col-12 md:col-12 lg:col-4">
            <div class="overview-box monthly-views">
                <div class="overview-title">VIEWS
                    <i class="pi pi-arrow-circle-down"></i>
                </div>
                <div class="overview-numbers">
                    <strong>6215</strong>/Day</div>
                <div class="overview-ratios">2% less than previous month</div>
                <img src="assets/layout/images/icon-views.png" alt="california-layout" />
            </div>
        </div>
        <div class="col-12 md:col-12 lg:col-4">
            <div class="overview-box monthly-users">
                <div class="overview-title">USERS
                    <i class="pi pi-arrow-circle-up"></i>
                </div>
                <div class="overview-numbers">
                    <strong>5288</strong>/Day</div>
                <div class="overview-ratios">9% more than previous month </div>
                <img src="assets/layout/images/icon-users.png" alt="california-layout" />
            </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
            <div class="stats">
                <div class="stats-icon">
                    <img src="assets/layout/images/icon-message.png" alt="california-layout" class="message-icon" />
                </div>
                <div class="stats-numbers">150</div>
                <div class="stats-title">Unread Messages</div>
                <div class="stats-subtitle">You stared
                    <span>5</span> messages today</div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
            <div class="stats">
                <div class="stats-icon">
                    <img src="assets/layout/images/icon-doc.png" alt="california-layout" class="doc-icon" />
                </div>
                <div class="stats-numbers">532</div>
                <div class="stats-title">Files Synced</div>
                <div class="stats-subtitle">
                    <span>21%</span> more than yesterday</div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
            <div class="stats">
                <div class="stats-icon">
                    <img src="assets/layout/images/icon-mark.png" alt="california-layout" class="mark-icon" />
                </div>
                <div class="stats-numbers">450</div>
                <div class="stats-title">Check-ins</div>
                <div class="stats-subtitle">
                    <span>12%</span> less than yesterday</div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
            <div class="stats">
                <div class="stats-icon">
                    <img src="assets/layout/images/icon-profile.png" alt="california-layout" class="profile-icon" />
                </div>
                <div class="stats-numbers">974</div>
                <div class="stats-title">Users Online</div>
                <div class="stats-subtitle">
                    <span>35210</span> total page view today</div>
            </div>
        </div>

        <div class="col-12 md:col-12 lg:col-6">
            <p-panel styleClass="daily-sales" header="Pedidos em Aberto">
                <p-table #dt [value]="orderList" dataKey="id" [paginator]="true" [rows]="5"
                [showCurrentPageReport]="true"
                [currentPageReportTemplate]="pageMessage"
                [rowsPerPageOptions]="[5,10,20]"
                [globalFilterFields]="['code','name', 'stage']"
                styleClass="p-datatable-responsive-demo p-datatable-striped"  [showCurrentPageReport]="true">

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 60px; text-align: center;" ><span translate>label.orderService</span> </th>
                    <th style="width: 150px; text-align: center;" pSortableColumn="name"><span translate>label.description</span> <p-sortIcon field="name"></p-sortIcon></th>
                    <th style="width: 150px; text-align: center;" pSortableColumn="companyName"><span>Cartório</span><p-sortIcon field="companyName"></p-sortIcon></th>
                    <th style="width: 180px; text-align: center;"><span translate>label.status</span></th>
                    <th style="width: 40px; text-align: center;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{item.code}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.companyName}}</td>
                    <span [class]="'status-badge'" [style]="{'background-color':''+item.statusColor+'', 'color': '#FFFFFF'}">{{item.statusName}} - {{item.statusDate | date: 'dd/MM/yyyy HH:mm'}}</span>
                    <td style="width: 40px; text-align: center;">
                        <p-button
                            icon="pi pi-search"
                            styleClass="p-button p-button-success p-m-1"
                            (click)="getOrder(item)" >
                        </p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
            </p-panel>
        </div>


        <div class="col-12 md:col-12 lg:col-6">

            <p-panel styleClass="chat" header="Chat">
                <ul>
                    <li class="message-from">
                        <div class="message-meta">
                            <img src="assets/layout/images/avatar-dani.png" alt="california-layout" />
                            <span class="name">Alice Santos - Recepção</span>
                            <span class="time">27 mins ago</span>
                        </div>
                        <div class="message">
                                    <span>Olá Cliente 1, tudo bem? Já recebemos o seu pedido e o mesmo foi encaminhado para a Janice que será a responsável pelo seu antendimento. Qualquer dúvida e só nos chamar aqui mesmo pelo chat</span>
                        </div>
                    </li>

                    <li class="message-from">
                        <div class="message-meta">
                            <img src="assets/layout/images/avatar-brenda.png" alt="california-layout" />
                            <span class="name">Janice - Atendimento</span>
                            <span class="time">21 mins</span>
                        </div>
                        <div class="message">
                            <span>Bom dia Cliente 1. Já estou analisando o seu pedido e em breve já te encaminho o orçamento inicial. 👍 </span>
                        </div>
                    </li>

                    <li class="message-own">
                        <div class="message">
                                    <span>Ok, obrigado, além do orçamento vc poderia e informar os documentos necessários e prazo de entrega?
                                    </span>
                        </div>
                        <div class="message-meta">
                            <img src="assets/layout/images/avatar-micheal.png" alt="california-layout" />
                            <span class="name">Cliente 1</span>
                            <span class="time">5 mins</span>
                        </div>
                    </li>

                    <li class="message-from">
                        <div class="message-meta">
                            <img src="assets/layout/images/avatar-brenda.png" alt="california-layout" />
                            <span class="name">Janice - Atendimento</span>
                            <span class="time">2 mins</span>
                        </div>
                        <div class="message">
                            <span>Perfeito! Junto com o orçamento te encaminhado a documentação, procedimentos e o prazo previsto. </span>
                        </div>
                    </li>
                </ul>

                <div class="write-message">
                    <i class="pi pi-paperclip"></i>
                    <input type="text" />
                </div>
            </p-panel>
        </div>




    </div>
</div>
