import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from './../../../environments/environment';

import { WoWorkFlowInput, WoWorkFlowItem, WoWorkFlowList, WoWorkFlowOutput, WoWorkFlowTaskItem, WoWorkFlowTaskList, WoWorkFlowTaskOutput, WoWorkFlowTaskUpdateInput, WoWorkFlowUpdateInput } from './wo-workflow.model';
import { DropDownMeta } from "../../shared/model/base-model";

@Injectable({
    providedIn: 'any'
})

export class WoWorkFlowService{

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/WorkFlow/';
    }

    getItem(id: string): Observable<WoWorkFlowItem> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<WoWorkFlowItem>(url);
    }

    getList(): Observable<WoWorkFlowList> {
        return this.httpClient.get<WoWorkFlowList>(this.environmentUrl);
    }

    addItem(model: WoWorkFlowOutput, userId: string) {

        let modelIns: WoWorkFlowInput = {};

        modelIns.code = model.code;
        modelIns.name = model.name;
        modelIns.icon = model.icon;
        modelIns.color = model.color;
        modelIns.userId = userId;

        return this.httpClient.post(this.environmentUrl, modelIns);
    }

    updItem(model: WoWorkFlowOutput, userId: string) {

        let url = this.environmentUrl + model.id;
        let modelUpd: WoWorkFlowUpdateInput = {};

        modelUpd.id = model.id;
        modelUpd.code = model.code;
        modelUpd.name = model.name;
        modelUpd.icon = model.icon;
        modelUpd.color = model.color;
        modelUpd.isActive = model.isActive;
        modelUpd.userId = userId;
        return this.httpClient.put(url, modelUpd);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

    getDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown";
        return this.httpClient.get<DropDownMeta>(url);
    }

    //====================================WorkFlowTasks================================
    getTaskItem(id: string): Observable<WoWorkFlowTaskItem> {
        let url = this.environmentUrl+"Task/" + id;
        return this.httpClient.get<WoWorkFlowItem>(url);
    }

    getTaskList(id: string): Observable<WoWorkFlowTaskList> {
        return this.httpClient.get<WoWorkFlowList>(this.environmentUrl+"Task?search="+id);
    }

    addTaskItem(model: WoWorkFlowTaskOutput) {
        return this.httpClient.post(this.environmentUrl+"Task/", model);
    }

    updTaskItem(model: WoWorkFlowTaskOutput) {
        let url = this.environmentUrl+"Task/" + model.id;
        return this.httpClient.put(url, model);
    }

    delTaskItem(workFlowId: string, id: string) {
        return this.httpClient.delete(this.environmentUrl+workFlowId+"/Task/" + id);
    }

    getTaskStatusList(woWorkFlowId: string, woOrderStatusId, language: string, woOrderId?: string): Observable<any>{
        let url = this.environmentUrl+"Task/" + woWorkFlowId + "/Status/"+woOrderStatusId+"?languageCode="+language+"&woOrderId="+woOrderId;
        return this.httpClient.get<any>(url);
    }


}
