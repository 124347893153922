import { WoOrderServiceService } from '../../../../pages/wo-order-service/wo-order-service/wo-order-service.service';
import { OnInit, Component, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { DropDown } from 'src/app/shared/model/base-model';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScUserService } from 'src/app/core/services/sc/sc-user.service';
import { SfcWoOrderOrderServiceOutput } from '../../wo-order-cartorio/wo-order-cartorio.model';
import { WoOrderCartorioService } from '../../wo-order-cartorio/wo-order-cartorio.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { WoNotarialSealOutput } from 'src/app/pages/wo-notarial-seal/wo-notarial-seal.model';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';



@Component({
    selector: 'sfc-autenticacao',
    templateUrl: './sfc-service-autenticacao.component.html',
    styleUrls: ['./sfc-service-autenticacao.component.scss']
})

export class SfcServiceAutenticacaoComponent implements OnInit, OnChanges, AfterContentChecked{


    receivedData: any;
    @Output() chosedItem = new EventEmitter();
    public item: SfcWoOrderOrderServiceOutput = {};
    public isDisabled: boolean = false;

    public userClerkId: string;
    public userClerkRoleId: string;

    public docTypeList: DropDown[] = [];
    public taxTypeList: DropDown[] = [];
    public userClerkList: DropDown[] = [];

    //TODO SfcAutenticacaoComponent - stampQty
    public stampQty: number = 1547;

    @Input() scPerson: string = '';
    @Input() canExecuteService: boolean = false;
    load: boolean = false;

    constructor(private orderServiceService: WoOrderServiceService,
                private personService: ScPersonService,
                private userService: ScUserService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private messageService: MessageService,
                private orderCartorioService: WoOrderCartorioService,
                private userTokenService: UserTokenService,
                private cdr: ChangeDetectorRef){ }

    ngOnInit(): void {
        this.item.scUserClerkId = this.userTokenService.getUserSignatureId();
        this.getData();
        this.getDocumentType();
        this.getTaxType();
        this.getUserClerk();


    }

    ngOnChanges(): void {
        // this.getData();
        // console.log("change");
        // console.log(this.receivedData);
    }

    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }

    getData(){
        this.orderCartorioService.dataChanged.subscribe((data: any) => {
            this.receivedData = data;



            if (this.receivedData.item == null || this.receivedData.item == "" || this.receivedData.item === undefined){
                this.item.name = this.receivedData.woOrderServiceName;
                this.item.woOrderId = this.receivedData.woOrderId;
                this.item.woOrderServiceId = this.receivedData.woOrderServiceId;
            }
            else{
                this.item = this.receivedData.item;
            }

            this.load = true;
          });

    }

    getDocumentType(){
        this.personService.getDocumentTypeDropDownList().subscribe(
            {
                next: (res) => {
                    if (res)
                        this.docTypeList = res.data;
                },
                error: (err) => {
                    this.messageError('api_response.ERROR', `SfcAutenticacaoComponent - getDocumentType : ${err}.`);
                }
            });
    }

    getTaxType(){
        this.orderServiceService.getTaxTypeDropDownList().subscribe(
            {
                next: (res) => {
                    if (res)
                        this.taxTypeList = res.data;
                },
                error: (err) => {
                    this.messageError('api_response.ERROR', `SfcAutenticacaoComponent - getTaxType : ${err}.`);
                }
            });
    }

    getUserClerk(){
        this.userService.getUserDropDownList('CLERK').subscribe(
            {
                next: (res) => {
                    if (res)
                        this.userClerkList = res.data;
                },
                error: (err) => {
                    this.messageError('api_response.ERROR', `SfcAutenticacaoComponent - getUserClerk : ${err}.`);
                }
            });
    }

    cancelItem(){
        this.item.action = 'cancel';
    }

    saveItem(){



        if (this.item.id == "0" || this.item.id == "0" || this.item.id === undefined) {

            this.orderCartorioService
                .addItem(this.item)
                .subscribe(
                {
                next: () => {
                    this.messageSuccess(this.translateService.instant('message.record_inserted'));
                    this.feedback("save");
                },
                error: (err) =>
                {
                    this.messageError('api_response.ERROR', `SfcAutenticacaoComponent - saveItem : ${err}.`);
                    console.log(err)
                }
            });


        } else {

            this.orderCartorioService
            .updItem(this.item)
            .subscribe(
                {
                next: () => {
                    this.messageSuccess(this.translateService.instant('message.record_updated'));
                    this.feedback("save");
                },
                error: (err) =>
                {
                    this.messageError('api_response.ERROR', `SfcAutenticacaoComponent - saveItem : ${err}.`);
                    console.log(err);
                }
            });
        }

    }

    getList() {
        // this.scClaimService.getDropDownList().subscribe(
        //   {
        //     next: (res) => {
        //         if (res)
        //             this.list = res.data;
        //             if (this.list !== null)
        //                 this.selectedItem = this.list.find(x => x.id == this.id?.toString());
        //     },
        //     error: (err) => {
        //         console.log(`ClaimDropDown - getList : ${err}.`);
        //     }
        // });
      }

    messageSuccess(message: string){
        this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000});
    }

    messageError(message: string, consoleMsg: string){
        this.messageService.add({severity: 'warn', summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000});
        console.log(consoleMsg);
    }

    reciverFeedbackTaxType(id: string) {
        if (id == null || id == "" || id == undefined) {
            this.item.woOrderServiceTaxTypeId = null;
        }
        else {
            this.item.woOrderServiceTaxTypeId = id;
        }
    }

    reciverFeedbackDocType(id: string) {
        if (id == null || id == "" || id == undefined) {
            this.item.scArchiveTypeId = null;
        }
        else {
            this.item.scArchiveTypeId = id;
        }
    }

    reciverFeedbackUserClerk(id: string) {
        if (id == null || id == "" || id == undefined) {
            this.item.scUserClerkId = null;
        }
        else {
            this.item.scUserClerkId = id;
        }
    }


    feedback(action: string){
        this.item.action = action;
        this.orderCartorioService.reciverFeedback(this.item);
    }

    reserveNotarialSeal(woNotarialSealOutput: WoNotarialSealOutput) {
        if (woNotarialSealOutput != null) {
            this.item.stamp = woNotarialSealOutput.code;
            console.log('woNotorialSealId ' + this.item.woNotorialSealId);
            this.item.woNotorialSealId = woNotarialSealOutput.id;
        } else {
            console.log(`SfcServiceAutenticacaoComponent - reserveNotarialSeal : Not Found`);
        }
    }

}
