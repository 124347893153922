import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChatboxComponent } from './chatbox.component';

@NgModule({

    declarations: [ChatboxComponent],
    exports: [ChatboxComponent],
    imports: [
        CommonModule,
        FormsModule
    ]
})

export class ChatboxModule {
}