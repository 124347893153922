import { ScPersonSpouse } from './../../../../../../models/response/sc-person.model';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { ScPerson, ScPersonOutput } from 'src/app/models/response/sc-person.model';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { formatDate } from '@angular/common';
import { ScUserService } from 'src/app/core/services/sc/sc-user.service';
import { MessageService } from 'primeng/api';
import { ConstantsService } from 'src/app/core/services/constants.service';
import { ScAddressService } from 'src/app/core/services/sc/sc-address.service';
import { ScCityOutput } from 'src/app/models/response/sc-city.response';
import { ScStateOutput } from 'src/app/models/response/sc-state.response';
import { ScCountryOutput } from 'src/app/models/response/sc-country.response';
import { ScStateService } from 'src/app/core/services/sc/sc-state.service';
import { ScCityService } from 'src/app/core/services/sc/sc-city.service';
import { ScCountryService } from 'src/app/core/services/sc/sc-country.service';

@Component({
  selector: 'app-sc-person-full-detail',
  templateUrl: './sc-person-full-detail.component.html',
  styleUrls: ['./sc-person-full-detail.component.scss']
})
export class ScPersonFullDetailComponent implements OnInit, AfterContentChecked {
  form!: FormGroup;
  formSpouse!: FormGroup;
  pageTitle!: string;
  pageSubTitle!: string;
  personId: string;
  userId: string = "";
  id: string = "_USER_ID";
  type: string = "client";
  public personItem: any;

  logComponent: string = "ScPersonFull";
  language: string = "pt-BR";
  flUpdate: boolean = false;
  flClient: boolean = false;
  public loading: boolean = false;
  public isPersonActive: boolean = false;
  public isPersonSpouseActive: boolean = false;

  listCountry: ScCountryOutput[] = [];
  listState: ScStateOutput[] = [];
  listCity: ScCityOutput[] = [];
  public loadListCountry: boolean = false;
  public loadListState: boolean = false;
  public loadListCity: boolean = false;

  listStateSpouse: ScStateOutput[] = [];
  listCitySpouse: ScCityOutput[] = [];
  public loadListStateSpouse: boolean = false;
  public loadListCitySpouse: boolean = false;

  isRegister = false;
  formattedAddress!: string;

  dialogAddressVisible: boolean = false;
  dialogPersonAddressVisible: boolean = false;
  zipCodeMask: string = "";

  uploadFilesModal: boolean = false;
  flagTabPanelSpouse: boolean = false;

  loaded: boolean = false;
  loadedSpouse: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private personService: ScPersonService,
    private translateConfigService: TranslateConfigService,
    private userTokenService: UserTokenService,
    private translateService: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private addressService: ScAddressService,
    private cityService: ScCityService,
    private stateService: ScStateService,
    private countryService: ScCountryService,
    private constantsService: ConstantsService,
    private messageService: MessageService,
    private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.onListenRoute();

    this.language = this.translateConfigService.getLanguage();
    this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'SC_PERSON_U']);
    this.translate();
    this.onBuildForm();
    this.getAllCountryLocal();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  translate() {
    const sleep = (ms) => new Promise(r => setTimeout(r, ms));
    (async () => {
      await sleep(50);

      const labAccessControl = this.translateService.instant('label.accessControl') + ' ';
      const labPage = ' ' + this.translateService.instant('label.person');
      this.breadcrumbService.setItems([{ label: labAccessControl }, { label: labPage, routerLink: ['person-full'] }]);

      if (this.id =="INSERT"){
        this.loaded = true;
        this.isRegister = true;
        if(this.type)
            this.pageTitle = this.translateService.instant('label.'+this.type+'Insert');
        else
            this.pageTitle = this.translateService.instant('label.clientInsert');
      }
      else{

        if (this.id == "_USER_ID") {
            this.flClient = this.flUpdate = true;
            this.pageTitle = this.translateService.instant('label.myPersonalData')
            this.userId = this.userTokenService.getUserId();
            this.personId = this.userTokenService.getPersonId();
        }
        else{
            this.personId = this.id;
            if(this.type)
                this.pageTitle = this.translateService.instant('label.'+this.type+'Edit');
            else
                this.pageTitle = this.translateService.instant('label.clientEdit');
        }

        this.isRegister = false;
        this.onGetPersonById(this.personId);
      }
    })();
  }


  unLoading() {

    const sleep = (ms) => new Promise(r => setTimeout(r, ms));

    //Need 50ms to get and set de language by cookie
    (async () => {
        await sleep(10000);
        this.loading = false;
    })();
}


  onListenRoute(){

       // this.activatedRoute.params.subscribe(params => {

    //   const { id } = params.get('id');
    //   const { type } = params.get('type');

    this.id = "_USER_ID"

    //});
  }

  onBuildForm(): void {
    this.form = this.formBuilder.group({

      cpf: [null],
      email: [null, [Validators.required, Validators.maxLength(250)]],
      name: [null, [Validators.required]],

      dateOfBirth: [null],
      scPersonGenderId: [null],
      phone_number: [null],
      mobile_number: [null],

      scPersonTypeNationalityId: [null],
      scCountryId: [null],
      scStateId: [null],
      scCityId: [null],

      scPersonMaritalStatusId: [null],
      scPersonMatrimonialPropertyId: [null],
      profession: [null, [Validators.maxLength(120)]],

      fatherName: [null, [Validators.maxLength(250)]],
      motherName: [null, [Validators.maxLength(250)]],

      observation: [null, [Validators.maxLength(2000)]],

      spouseId: [null],
      //spouse: [null, [Validators.required]],
      // scPersonSpouse: [null],
      // flagClient: [null],
      // flagSupplier: [null],
      // flagCollaborator: [null],
      //address: [{value: null, disabled: true}],

    });

    this.formSpouse = this.formBuilder.group({
        cpf: [null],
        email: [null, Validators.maxLength(250)],
        name: [null, Validators.maxLength(250)],

        dateOfBirth: [null],
        scPersonGenderId: [null],
        phone_number: [null],
        mobile_number: [null],

        scPersonTypeNationalityId: [null],
        scCountryId: [null],
        scStateId: [null],
        scCityId: [null],

        scPersonMaritalStatusId: [null],
        scPersonMatrimonialPropertyId: [null],
        profession: [null, [Validators.maxLength(120)]],

        fatherName: [null, [Validators.maxLength(250)]],
        motherName: [null, [Validators.maxLength(250)]],

        observation: [null, [Validators.maxLength(2000)]],
    });
  }

    onSetDataForm(person: any): void {



    this.isPersonActive = person.data.isActive; //cpf read only


    this.form.addControl('id', this.formBuilder.control(null));
    this.form.addControl('scPersonMaritalStatusId', this.formBuilder.control(null));
    this.form.addControl('scPersonTypeNationalityId', this.formBuilder.control(null));
    this.form.addControl('scPersonMatrimonialPropertyId', this.formBuilder.control(null));
    this.form.addControl('scPersonGenderId', this.formBuilder.control(null));
    this.form.addControl('scPersonSpouse', this.formBuilder.control(null));
    this.form.addControl('spouseId', this.formBuilder.control(null));

    this.form.patchValue({ id: person.id });

    if (person.data != undefined) {

      if (person.data.dateOfBirth !== undefined && person.data.dateOfBirth != null){
        const dataNasc = formatDate(new Date(person.data.dateOfBirth), 'dd/MM/yyyy', 'pt-BR');
        //const dataNasc = formatDate(new Date(person.data.dateOfBirth), 'yyyy-MM-dd', 'en-US');
        this.form.patchValue({ dateOfBirth: dataNasc });
      }

      this.form.addControl('scPersonGenderId', person.data.scPersonGenderId);
      this.form.patchValue({ phone_number: person.data.telephone });
      this.form.patchValue({ mobile_number: person.data.mobile });

    //   if (person.data.telephone !== undefined && person.data.telephone != null)
    //     this.form.patchValue({ phone_number: "(" + ("00" + person.data.telephone.ddd).slice(-2) + ")" + ("00000000000" + person.data.telephone.phone).slice(-9) });

    //   if (person.data.mobile !== undefined && person.data.mobile != null) {
    //     this.form.patchValue({ mobile_number: "(" + ("00" + person.data.mobile.ddd).slice(-2) + ")" + ("00000000000" + person.data.mobile.phone).slice(-9) });
    //   }

      this.form.addControl('scPersonTypeNationalityId', person.data.scPersonTypeNationalityId);
      if (person.data.scCountryId !== undefined && person.data.scCountryId != null && person.data.scCountryId != '')
        this.getAllUFLocal(person.data.scCountryId, 'person');
      if (person.data.scStateId !== undefined && person.data.scStateId != null && person.data.scStateId != '')
        this.getAllCitiesByUFLocal(person.data.scStateId, 'person');

      this.form.addControl('scPersonMaritalStatusId', person.data.scPersonMaritalStatusId);
      this.form.addControl('scPersonMatrimonialPropertyId', person.data.scPersonMatrimonialPropertyId);



      if (person.data.scPersonSpouse !== undefined && person.data.scPersonSpouse != undefined && person.data.scPersonSpouse.id !== undefined && person.data.scPersonSpouse.id != null){
          this.flagTabPanelSpouse = true;
          this.form.patchValue({ scPersonSpouse: person.data.scPersonSpouse });
          this.form.patchValue({ spouseId: person.data.scPersonSpouse.id });

            this.onGetPersonSpouseById(person.data.spouseId);
      }
      else if (person.data.scPersonMaritalStatusId != undefined && person.data.scPersonMaritalStatusId.toUpperCase() == 'CDC670D0-C270-4FC7-99F0-1A3953664179'){
          this.flagTabPanelSpouse = true;
      } //TODO - Refatorar para pegar o ID do casado


        this.form.patchValue(person.data);
    }
    this.loaded = true;
  }

  onSetDataFormSpouse(person: any): void {

    this.isPersonSpouseActive = person.data.scPersonSpouseOutput.isActive; //cpf read only

    this.formSpouse.addControl('id', this.formBuilder.control(null));
    this.formSpouse.addControl('scPersonMaritalStatusId', this.formBuilder.control(null));
    this.formSpouse.addControl('scPersonTypeNationalityId', this.formBuilder.control(null));
    this.formSpouse.addControl('scPersonMatrimonialPropertyId', this.formBuilder.control(null));
    this.formSpouse.addControl('scPersonGenderId', this.formBuilder.control(null));
    this.formSpouse.addControl('scPersonSpouse', this.formBuilder.control(null));
    this.formSpouse.addControl('spouseId', this.formBuilder.control(null));

    this.formSpouse.patchValue({ id: person.id });

    if (person.data != undefined && person.data.scPersonSpouseOutput != undefined) {


      if (person.data.scPersonSpouseOutput.dateOfBirth !== undefined && person.data.scPersonSpouseOutput.dateOfBirth != null){
        const dataNasc = formatDate(new Date(person.data.scPersonSpouseOutput.dateOfBirth), 'dd/MM/yyyy', 'pt-BR');
        //const dataNasc = formatDate(new Date(person.data.scPersonSpouseOutput.dateOfBirth), 'yyyy-MM-dd', 'en-US');
        this.formSpouse.patchValue({ dateOfBirth: dataNasc });
      }

      this.formSpouse.addControl('scPersonGenderId', person.data.scPersonSpouseOutput.scPersonGenderId);
      this.formSpouse.patchValue({ phone_number: person.data.scPersonSpouseOutput.telephone });
      this.formSpouse.patchValue({ mobile_number: person.data.scPersonSpouseOutput.mobile });

    //   if (person.data.scPersonSpouseOutput.telephone !== undefined && person.data.scPersonSpouseOutput.telephone != null)
    //     this.form.patchValue({ phone_number: "(" + ("00" + person.data.scPersonSpouseOutput.telephone.ddd).slice(-2) + ")" + ("00000000000" + person.data.scPersonSpouseOutput.telephone.phone).slice(-9) });

    //   if (person.data.scPersonSpouseOutput.mobile !== undefined && person.data.scPersonSpouseOutput.mobile != null) {
    //     this.form.patchValue({ mobile_number: "(" + ("00" + person.data.scPersonSpouseOutput.mobile.ddd).slice(-2) + ")" + ("00000000000" + person.data.scPersonSpouseOutput.mobile.phone).slice(-9) });
    //   }

      this.formSpouse.addControl('scPersonTypeNationalityId', person.data.scPersonSpouseOutput.scPersonTypeNationalityId);
      if (person.data.scPersonSpouseOutput.scCountryId !== undefined && person.data.scPersonSpouseOutput.scCountryId != null && person.data.scPersonSpouseOutput.scCountryId != '')
        this.getAllUFLocal(person.data.scPersonSpouseOutput.scCountryId, 'person');
      if (person.data.scPersonSpouseOutput.scStateId !== undefined && person.data.scPersonSpouseOutput.scStateId != null && person.data.scPersonSpouseOutput.scStateId != '')
        this.getAllCitiesByUFLocal(person.data.scPersonSpouseOutput.scStateId, 'person');

      this.formSpouse.addControl('scPersonMaritalStatusId', person.data.scPersonSpouseOutput.scPersonMaritalStatusId);
      this.formSpouse.addControl('scPersonMatrimonialPropertyId', person.data.scPersonSpouseOutput.scPersonMatrimonialPropertyId);

      if (person.data.scPersonSpouseOutput.scPersonSpouse != null && person.data.scPersonSpouseOutput.scPersonSpouse.id !== undefined && person.data.scPersonSpouseOutput.scPersonSpouse.id != null) {
          this.flagTabPanelSpouse = true;
          this.formSpouse.patchValue({ scPersonSpouse: person.data.scPersonSpouseOutput.scPersonSpouse });
          this.formSpouse.patchValue({ spouseId: person.data.scPersonSpouseOutput.scPersonSpouse.id });
          this.onGetPersonSpouseById(person.data.scPersonSpouseOutput.spouseId);
      }
      else if (person.data.scPersonSpouseOutput.scPersonMaritalStatusId != undefined && person.data.scPersonMaritalStatusId.toUpperCase() == 'CDC670D0-C270-4FC7-99F0-1A3953664179'){
          this.flagTabPanelSpouse = true;
      } //TODO - Refatorar para pegar o ID do casado


        this.formSpouse.patchValue(person.data);
    }
    this.loadedSpouse = true;
  }

  onGetPersonById(id: string): void {
    this.personService.getPersonById(this.language, id).subscribe({
      next: (response) => {
        this.onSetDataForm(response);
      //  console.log(response);
        this.onSetDataFormSpouse(response);
      },
    });
  }

  onGetPersonSpouseById(id: string): void {
    this.personService.getPersonById(this.language, id).subscribe({
      next: (response) => {
        this.onSetDataFormSpouse(response);
      },
    });
  }

  onSavePerson(payload: ScPersonOutput): void {
    this.personService.addItemPerson(payload).subscribe({
        next: (res) => {
            if (res)
                this.messageSuccess(this.translateService.instant('message.record_updated'));
                this.loading = false;
        },
        error: (err) => {
            console.log(`${this.logComponent} - save : ${err}.`);
            this.messageError(this.translateService.instant('message.update_error'));
            this.loading = false;
        }
    });
    this.loading = false;
  }

  onUpdatePerson(payload: ScPersonOutput): void {
    this.personService.updItemPersonClient(payload).subscribe({
        next: (res) => {
            if (res)
                this.messageSuccess(this.translateService.instant('message.record_updated'));
                this.loading = false;
        },
        error: (err) => {
            console.log(`${this.logComponent} - save : ${err}.`);
            this.messageError(this.translateService.instant('message.update_error'));
            this.loading = false;
        }
    });
    this.loading = false;
  }

  onSaveSpouse(payload: ScPersonOutput): void {
    this.personService.addItemPerson(payload).subscribe({
      next: () => {
        this.onGoBack()
      }
    });
  }

  onUpdateSpouse(payload: ScPersonOutput): void {
    this.personService.updItemPerson(payload).subscribe({
      next: () => {
        this.onGoBack();
      }
    });
  }

  onConfirm(): void {
    this.loading = true;
    const payload = this.form.value;
    const spouse = this.formSpouse.value;

    let person: ScPersonOutput = {
      id: payload.id,
      name: payload.name,
      cpf: payload.cpf,
      gender: 0,
      telephone: payload.phone_number,
      mobile: payload.mobile_number,
      email: payload.email,
      fatherName: payload.fatherName,
      motherName: payload.motherName,
      maritalStatus: 0,
      dateOfBirth: payload.dateOfBirth,
      scCompanyId: this.userTokenService.getCompanyId(),
      scUserId: this.userTokenService.getUserId(),
      spouse: null,
      roles: null,

      spouseId: payload.spouseId,

      scPersonSpouseOutput: spouse,
      ScPersonSpouse: spouse,
      scPersonMaritalStatusId: payload.scPersonMaritalStatusId,
      profession: payload.profession,
      observation: payload.observation,
      scPersonTypeNationalityId: payload.scPersonTypeNationalityId,
      scPersonMatrimonialPropertyId: payload.scPersonMatrimonialPropertyId,
      scPersonGenderId: payload.scPersonGenderId,
      //scPersonScAddress: ScAddressScPerson[];
      scCountryId: payload.scCountryId,
      scStateId: payload.scStateId,
      scCityId: payload.scCityId
    };

    if (this.form.valid) {
        if (this.flagTabPanelSpouse){
            if (!this.formSpouse.valid){
                this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), 'É necessário preencher os dados da aba Cônjuge');
                return;
            }
          //  person.ScPersonSpouse = this.onConfirmSpouse();
        }
        else
            person.ScPersonSpouse = null;

        this.isRegister ? this.onSavePerson(person) : this.onUpdatePerson(person);
    }
    else{
        this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), 'É necessário preencher os dados da aba Dados Pessoais');
        return;
    }
  }

  onConfirmSpouse() {//: ScPerson
    const payload = this.formSpouse.value;
    let regexPhone = new RegExp('\\(([^()]+)\\)', 'g');
    let regexMobile = new RegExp('\\(([^()]+)\\)', 'g');
    let phoneNumber: string = "";
    let mobileNumber: string = "";
    let userId : string = "";

    if (this.personItem != undefined && this.personItem.data != undefined && this.personItem.data.scPersonSpouseOutput != null){
        userId = this.personItem.data.scPersonSpouseOutput.ScUserId

    }
    // let spouse: ScPerson = {
    //     id: payload.id,
    //     name: payload.name,
    //     cpf: payload.cpf,
    //     gender: 0,
    //     telephone: payload.phone_number,
    //     mobile: payload.mobile_number,
    //     email: payload.email,
    //     fatherName: payload.fatherName,
    //     motherName: payload.motherName,
    //     maritalStatus: 0,
    //     dateOfBirth: payload.dateOfBirth,
    //     scCompanyId: this.userTokenService.getCompanyId(),

    //     scUserId: userId,
    //     spouse: null,
    //     roles: null,

    //     spouseId: payload.spouseId,
    //    // ScPersonSpouse: payload.ScPersonSpouse,
    //     scPersonMaritalStatusId: payload.scPersonMaritalStatusId,
    //     profession: payload.profession,
    //     observation: payload.observation,
    //     scPersonTypeNationalityId: payload.scPersonTypeNationalityId,
    //     scPersonMatrimonialPropertyId: payload.scPersonMatrimonialPropertyId,
    //     scPersonGenderId: payload.scPersonGenderId,
    //     //scPersonScAddress: ScAddressScPerson[];
    //     scCountryId: payload.scCountryId,
    //     scStateId: payload.scStateId,
    //     scCityId: payload.scCityId
    // };

    // if (this.formSpouse.valid) {
    //   spouse.id === undefined || spouse.id == null || spouse.id == '' ? this.onSaveSpouse(spouse) : this.onUpdateSpouse(spouse);
    // }

    //return spouse;
  }

  onGoBack(): void {
    this.router.navigate(['']);
  }

  isFieldValid(field: string) {
    return !this.form.get(field)?.valid && this.form.get(field)?.touched;
  }

  isFieldValidDropdown(field: string) {
    return !this.form.get(field)?.valid;
  }

  isFieldValidSpouse(field: string) {
    return !this.formSpouse.get(field)?.valid;
  }

  getPersonSearchByCpf(cpf: string) {
    // this.fieldValidation = true;
    // this.item = {} as ScPersonOutput;
    // this.item.cpf = cpf;
    // this.item.telephone = {} as PhoneNumber;
    // this.item.mobile  = {} as PhoneNumber;
    // this.scPersonService.getListPerson(this.language, cpf, '', null).subscribe(
    //   {
    //     next: (res) => {
    //       if (res && res.data.length > 0) {
    //         this.item = res.data[0];
    //         this.item.flagClient = this.item.flagClient == true || this.flagPersonType == 'client' ? true : false;
    //         this.item.flagSupplier = this.item.flagSupplier == true || this.flagPersonType == 'supplier' ? true : false;
    //         this.item.flagCollaborator = this.item.flagCollaborator == true || this.flagPersonType == 'collaborator' ? true : false;
    //         this.getListAddress();
    //       } else {

    //       }
    //     },
    //     error: (err) => {
    //       console.log(`${this.logComponent} - getPersonSearchByCpf : ${err}.`);
    //     }
    //   });
  }

  onCompleteCpf(form: any) {
    this.getPersonSearchByCpf(form.form.controls.cpf.value.replace(/\D/g, ''));
  }

  getAllCountryLocal() {
    this.loadListCountry = false;
    this.listCountry = [];
    this.countryService.getList().subscribe(
      {
        next: (res) => {
          if (res && res.data.length > 0) {
            this.listCountry = res.data.filter(f => f.name.toLowerCase() != 'brasil');

            if (res.data.filter(f => f.name.toLowerCase() == 'brasil').shift()) {
              let countryDefault = res.data.filter(f => f.name.toLowerCase() == 'brasil').shift();
              this.listCountry.unshift(countryDefault);
            }
          }
          this.loadListCountry = true;
        },
        error: (err) => {
          console.log(`${this.logComponent} - getAllCountryLocal : ${err}.`);
          this.loadListCountry = true;
        }
      });
  }

  getAllUFLocal(countryId: string, type: string) {
    this.listState = [];
    this.stateService.getDropDownList(countryId).subscribe(
      {
        next: (res) => {
          if (type == "person") {
            if (res && res.data.length > 0) {
              this.listState = res.data;
            }
            this.loadListState = true;
          } else if (type == "spouse") {
            if (res && res.data.length > 0) {
              this.listStateSpouse = res.data;
            }
            this.loadListStateSpouse = true;
          }
        },
        error: (err) => {
          console.log(`${this.logComponent} - getAllUFLocal : ${err}.`);
          this.loadListState = true;
        }
      });
  }

  getAllCitiesByUFLocal(stateId: string, type: string) {
    this.listCity = [];
    this.cityService.getDropDownList(stateId).subscribe(
      {
        next: (res) => {
          if (type == "person") {
            if (res && res.data.length > 0) {
              this.listCity = res.data.sort((a, b) => a.name.localeCompare(b.name));
            }
            this.loadListCity = true;
          } else if (type == "spouse") {
            if (res && res.data.length > 0) {
              this.listCitySpouse = res.data.sort((a, b) => a.name.localeCompare(b.name));
            }
            this.loadListCitySpouse = true;
          }
        },
        error: (err) => {
          console.log(`${this.logComponent} - getAllCitiesByUFLocal : ${err}.`);
          this.loadListCity = true;
        }
      });
  }

  reciverFeedbackPersonMaritalStatus(event) {
    if (event != null && event !== undefined && event.name.toLowerCase() === "casado") {
      this.flagTabPanelSpouse = true;
    } else {
      this.flagTabPanelSpouse = false;
    }
    this.form.patchValue({ scPersonMaritalStatusId: event != null && event !== undefined ? event.id : null });
  }

  reciverFeedbackPersonTypeNationality(event) {
    this.form.patchValue({ scPersonTypeNationalityId: event });
  }

  reciverFeedbackPersonMatrimonialProperty(event) {
    this.form.patchValue({ scPersonMatrimonialPropertyId: event });
  }

  reciverFeedbackPersonGender(event) {
    this.form.patchValue({ scPersonGenderId: event });
  }

  reciverFeedbackCountry(countryId: string, type: string) {
    this.form.patchValue({ scCountryId: countryId });
    this.getAllUFLocal(countryId, type);
  }

  reciverFeedbackState(stateId: string, type: string) {
    this.form.patchValue({ scStateId: stateId });
    this.getAllCitiesByUFLocal(stateId, type);
  }

  reciverFeedbackCity(cityId: string) {
    this.form.patchValue({ scCityId: cityId });
  }

  //--------------------------------------------------------
  reciverFeedbackPersonMaritalStatusSpouse(event) {
    this.formSpouse.patchValue({ scPersonMaritalStatusId: event != null && event !== undefined ? event.id : null });
  }

  reciverFeedbackPersonTypeNationalitySpouse(event) {
    this.formSpouse.patchValue({ scPersonTypeNationalityId: event });
  }

  reciverFeedbackPersonMatrimonialPropertySpouse(event) {
    this.formSpouse.patchValue({ scPersonMatrimonialPropertyId: event });
  }

  reciverFeedbackPersonGenderSpouse(event) {
    this.formSpouse.patchValue({ scPersonGenderId: event });
  }

  reciverFeedbackCountrySpouse(countryId: string, type: string) {
    this.formSpouse.patchValue({ scCountryId: countryId });
    this.getAllUFLocal(countryId, type);
  }

  reciverFeedbackStateSpouse(stateId: string, type: string) {
    this.formSpouse.patchValue({ scStateId: stateId });
    this.getAllCitiesByUFLocal(stateId, type);
  }

  reciverFeedbackCitySpouse(cityId: string) {
    this.formSpouse.patchValue({ scCityId: cityId });
  }
  //--------------------------------------------------------



  uploadFiles() {
    this.uploadFilesModal = true;
  }


  messageSuccess(message: string){
    this.messageService.add({severity: this.constantsService.SEVERITY_SUCCESS, summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000});
  }

  messageError(message: string){
      this.messageService.add({severity: this.constantsService.SEVERITY_WARNING, summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000});
  }

}
