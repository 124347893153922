import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SfcWoOrderClientItem, SfcWoOrderClientList } from 'src/app/models/response/sfc-wo-order-client.model';
import { WoOrderOutput } from 'src/app/pages/wo-order/wo-order.model';



@Injectable({
    providedIn: 'root'
  })

export class SfcWoOrderClientService{

    private environmentUrl: string = '';


    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Order/Client/';
    }

    getItem(id: string): Observable<WoOrderOutput> {
        let url = this.environmentUrl+ id;
        return this.httpClient.get<WoOrderOutput>(url);
    }

    getList() : Observable<SfcWoOrderClientList>{
        let url = this.environmentUrl;
        return this.httpClient.get<SfcWoOrderClientList>(url);
    }


}


