import { Message } from 'primeng/api';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { delay } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { DataPwdForgot } from 'src/app/core/auth.model';
import { StringFunctionsService } from 'src/app/core/services/shared-functions/string-functions.service';
import { ScUserService } from 'src/app/core/services/sc/sc-user.service';


@Component({
    templateUrl:'./signup.component.html',
    styleUrls:  ['./signup.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SignUpComponent implements OnInit{

    public items: MenuItem[];
    public email: string;
    public name: string;
    public pwd: string;
    public pwd2: string;
    public accessCode: string;
    public lang: string = "pt-BR";
    public valid: boolean = true;
    public activeIndex: number = 0;
    public labelSignUp: string = "";
    public labelConfirmation: string = "";
    public labelSignUpDone: string = "";
    public loading: boolean = false;
    public isEmail: boolean = true;



    constructor(
        private router: Router,
        private translateConfigService: TranslateConfigService,
        private translateService: TranslateService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private userService: ScUserService,
        private stringFunctionsService: StringFunctionsService
    ){}



    ngOnInit() {
        this.lang = this.translateConfigService.getLanguage();
        this.changeLanguage(this.lang);
    }


    changeLanguage(type: string) {

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        //Need 50ms to get and set de language by cookie
        this.translateConfigService.changeLanguage(type);
        (async () => {
            await sleep(50);
            this.translate();
        })();
    }

    translate(){
        this.labelSignUp = this.translateService.instant('label.signUp');
        this.labelConfirmation = this.translateService.instant('label.email_confirmation');
        this.labelSignUpDone = this.translateService.instant('label.signUpDone');

        this.items = [{label: this.labelSignUp},{label: this.labelSignUpDone},{label: this.labelConfirmation}];


    }

    unLoading() {

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        //Need 50ms to get and set de language by cookie
        (async () => {
            await sleep(10000);
            this.loading = false;
        })();
    }

    save(): boolean{


        this.isEmail = this.userService.isEmail(this.email);

        if (this.name && this.email && this.isEmail && this.pwd && this.pwd == this.pwd2){
            console.log("entrou");
            this.loading = true;
            this.valid = false;
            this.authService
            .signUpClient(this.email, this.name, this.pwd, this.lang)
            .subscribe(
                {
                    next: (res) =>{
                        if (res){
                            console.log(res.data.scUserId);
                            if (res.data.scUserId != null && res.data.scUserId != undefined){
                                console.log("ok");
                                this.valid = true;
                                this.loading = false;
                                this.activeIndex = 1
                            }
                        }
                        if (!this.valid){
                            this.loading = false;
                            console.log('User => NULL');
                            this.notificationsService.toastNotify('error',this.translateService.instant('label.signUp'), this.translateService.instant('api_response.'+this.stringFunctionsService.getFirstWord(res.data.returnMessage)));
                        }
                        this.loading = false;
                    },
                    error: (err) => {
                        this.loading = false;
                        this.valid = false;
                        console.log('SignUp Error:');
                        console.log(err.Message);
                        this.notificationsService.toastNotify('error',this.translateService.instant('label.signUp'), this.translateService.instant('api_response.ERROR_SIGNUP'));
                    }
                });
        }
        else
        {
            console.log("saiu");
            this.valid = false;
        }

        this.unLoading();

        return false;
    }

    confirmEmail(): boolean{

        if (this.accessCode){
            this.loading = true;
            this.authService
            .signUpCode(this.email, this.accessCode)
            .subscribe(
                {
                    next: (res) =>{
                        console.log(res);
                        if (res){
                            this.valid = false;
                            if (res.data.returnCode != null && res.data.returnCode != undefined && res.data.returnCode == "OK"){
                                this.valid = true;
                                this.loading = false;
                                this.activeIndex = 2;
                            }
                        }

                        if (!this.valid){
                            this.notificationsService.toastNotify('error',this.translateService.instant('label.signUp'), this.translateService.instant('api_response.FORGOT_CODE_INVALID'));
                        }
                    },
                    error: (err) => {
                        console.log(err);
                        this.valid = false;
                        this.loading = false;
                        console.log('SignUp Error:');
                        console.log(err);
                        this.notificationsService.toastNotify('error',this.translateService.instant('label.signUp'), this.translateService.instant('api_response.FORGOT_CODE_INVALID'));
                    }
                });
        }
        else
            this.notificationsService.toastNotify('error',this.translateService.instant('label.signUp'), this.translateService.instant('api_response.FORGOT_CODE_INVALID'));


        this.unLoading();
        return false;
    }

    sendEmail(): boolean{
        this.loading = true;
        this.authService
        .signUpSendMail(this.email)
        .subscribe(
            {
                next: (res) =>{
                    if (res){
                        console.log(res);
                        this.valid = true;
                        this.loading = false;
                        this.notificationsService.toastNotify('success',this.translateService.instant('label.signUp'), this.translateService.instant('label.emailValidationSent'));
                        this.valid = true;
                    }
                },
                error: (err) => {
                    console.log(err);
                    this.valid = false;
                    this.loading = false;
                    console.log('sendEmail Error:');
                    console.log(err);
                    this.notificationsService.toastNotify('error',this.translateService.instant('label.signUp'), this.translateService.instant('api_response.ERROR_SIGNUP'));
                }
            });
        this.unLoading();
        return false;
    }



    ngOnDestroy() {
        // if (this.subscription) {
        //     this.subscription.unsubscribe();
        // }
    }

    login()
    {
        this.router.navigate(['/login']);
    }
}

