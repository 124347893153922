import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom, of } from "rxjs";
import { ScCountryList, ScCountryOutput } from "src/app/models/response/sc-country.response";
import { DropDown, DropDownMeta } from "src/app/shared/model/base-model";
import { environment } from "src/environments/environment";






@Injectable({
    providedIn: 'root'
  })
  export class ScCountryService {

    environmentUrl = '';

    constructor(private http: HttpClient) {
      this.environmentUrl = environment.ApiCore+"/Country/";
     }


    getList() : Observable<ScCountryList>{
            let url = this.environmentUrl;
            return this.http.get<ScCountryList>(url);
        }


    getItem(id: string) : Observable<ScCountryOutput>{
        let url = this.environmentUrl+id;
        return this.http.get<ScCountryOutput>(url);
    }


    add(item: ScCountryOutput) : Observable<ScCountryOutput>{
        let url = this.environmentUrl
        return this.http.put(url, item);
    }

    Upd(item: ScCountryOutput) : Observable<ScCountryOutput>{
        let url = this.environmentUrl+item.id;
        return this.http.post(url, item);
    }

    getDropDownList(): Observable<DropDownMeta>{
        let url = this.environmentUrl + "DropDown";
        return this.http.get<DropDownMeta>(url);
    }


  }
