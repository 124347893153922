import { DropDown } from 'src/app/shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ScAddressService } from 'src/app/core/services/sc/sc-address.service';
import { ScStateService } from 'src/app/core/services/sc/sc-state.service';



@Component({
    selector: 'sf-sc-state-dropdown',
    templateUrl: './sc-state-dropdown.component.html',
    styleUrls: ['./sc-state-dropdown.component.scss']
})

export class ScStateDropdownComponent implements OnInit, OnChanges {

    public list: DropDown[];
    @Input() placeHolderLanguage: string;
    public selectedItem: DropDown;

    @Input() scCountryId: string;
    @Input() scStateId: string;
    @Input() languageCode: string = "pt-BR";
    @Input() disabled: boolean = false;
    @Output() choseItem = new EventEmitter();
    loading: boolean = false;

    constructor(private stateService: ScStateService) { }

    ngOnInit(): void {
        this.loading = true;
        this.getList();
    }

    ngOnChanges(): void {
        this.getList();
    }

    getList() {
        this.stateService.getDropDownList(this.scCountryId).subscribe(
            {
                next: (res) => {
                    if (res)
                        this.list = res.data;
                    if (this.list !== null) {
                        this.selectedItem = this.list.find(x => x.id == this.scStateId);
                        if (this.selectedItem == null || this.selectedItem.id == undefined) {
                            this.selectedItem = this.list[0];
                            this.feedback();
                        }


                    }
                    this.loading = false;
                },
                error: (err) => {
                    console.log(`StateDropDown - getList : ${err}.`);
                    this.loading = false;
                }
            });
    }

    feedback() {
        this.choseItem.emit(this.selectedItem)
    }

}
