import { ScUser } from './../../../models/response/sc-user.model';

import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { ScPerson, ScPersonOutput } from 'src/app/models/response/sc-person.model';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { formatDate } from '@angular/common';
import { ScUserService } from 'src/app/core/services/sc/sc-user.service';
import { MessageService } from 'primeng/api';
import { ConstantsService } from 'src/app/core/services/constants.service';
import { ScAddressService } from 'src/app/core/services/sc/sc-address.service';
import { BreadCrumb } from 'src/app/shared/components/sf-breadcrumb/sf-breadcrumb.model';
import { SfMessageService } from 'src/app/core/services/core/sf-message.service';
import { ScCountryOutput } from 'src/app/models/response/sc-country.response';
import { ScStateOutput } from 'src/app/models/response/sc-state.response';
import { ScCityOutput } from 'src/app/models/response/sc-city.response';
import { ScCityService } from 'src/app/core/services/sc/sc-city.service';
import { ScCountryService } from 'src/app/core/services/sc/sc-country.service';
import { ScStateService } from 'src/app/core/services/sc/sc-state.service';

@Component({
  selector: 'sc-person-full-client',
  templateUrl: './sc-person-full-client.component.html',
  styleUrls: ['./sc-person-full-client.component.scss']
})
export class ScPersonFullClientComponent implements OnInit, OnChanges, AfterContentChecked {


  @Input() id: string;

  public receivedData: any;
  public receivedAction: string;

  form!: FormGroup;
  personId: string;
  userId: string = "";
  public item: ScPersonOutput = {};

  public msgPrefix: string = '';
  public message: string = '';
  public logComponent: string = "ScPersonFullClientComponent";
  public language: string = "pt-BR";

  public isLoaded: boolean = false;



  public loading: boolean = false;

  public isPersonActive: boolean = false;

    public listCountry: ScCountryOutput[] = [];
    public listState: ScStateOutput[] = [];
    public listCity: ScCityOutput[] = [];
    public loadListCountry: boolean = false;
    public loadListState: boolean = false;
    public loadListCity: boolean = false;

  isRegister = false;
  formattedAddress!: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private personService: ScPersonService,
    private translateConfigService: TranslateConfigService,
    private userTokenService: UserTokenService,
    private translateService: TranslateService,
    private constantsService: ConstantsService,
    private messageService: SfMessageService,
    private notificationsService: NotificationsService,
    //private addressService: ScAddressService,
    private cityService: ScCityService,
    private countryService: ScCountryService,
    private stateService: ScStateService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.language = this.translateConfigService.getLanguage();
    this.translate();
    this.onBuildForm();
    console.log(this.id);
    this.getPersonData(this.id);
    this.getAllCountryLocal();
  }

  ngOnChanges(): void {
    console.log(this.id);
    this.getPersonData(this.id);
    this.feedbackAction();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  onBuildForm(): void {
    this.form = this.formBuilder.group({

      cpf: [null],
      email: [null, [Validators.required, Validators.maxLength(250)]],
      name: [null, [Validators.required]],

      dateOfBirth: [null],
      scPersonGenderId: [null],
      telephone: [null],
      mobile: [null],

      scPersonTypeNationalityId: [null],
      scCountryId: [null],
      scStateId: [null],
      scCityId: [null],

      scPersonMaritalStatusId: [null],
      scPersonMatrimonialPropertyId: [null],
      profession: [null, [Validators.maxLength(120)]],
      fatherName: [null, [Validators.maxLength(250)]],
      motherName: [null, [Validators.maxLength(250)]],
      observation: [null, [Validators.maxLength(2000)]],

      spouseId: [null],
    });
  }

  getPersonData(id: string) {

    if (id != null && id != undefined && id != "") {
        this.onGetPersonById(id);
    }
    else{

        this.personService.dataChanged.subscribe((data: any) => {
            this.receivedData = data;

            if (this.receivedData != null && this.receivedData != "" && this.receivedData !== undefined) {
                this.item = this.receivedData;
                this.onSetDataForm(this.item);
            }

        });
    }
    this.isLoaded = true;

  }

  onGetPersonById(id): void {

    if (id != null && id != undefined && id != "") {
        this.personService.getPersonById(this.language, id).subscribe( {
               next: (res) => {
                   if (res) {
                       this.receivedData = res;
                       this.item = res;
                       console.log(this.item);
                       this.onSetDataForm(this.item);
                   }
               },
               error: (err) => {
                   console.log('ScPersonFullClientComponent - getPersonData');
                   console.log(err);
               }
           });
       }
  }

  feedbackAction() {



    this.personService.actionChanged.subscribe((data: any) => {
      this.receivedAction = data;



      if (this.form.valid) {
        console.log("entrou");
        this.onSavePerson(this.form.value);
      }
      else {
        this.messageService.messageWarning(this.msgPrefix + this.translateService.instant('label.checkRequiredFields'));
        return;
      }

    });
  }


  unLoading() {

    const sleep = (ms) => new Promise(r => setTimeout(r, ms));

    //Need 50ms to get and set de language by cookie
    (async () => {
      await sleep(10000);
      this.loading = false;
    })();
  }






  onSetDataForm(person: any): void {

    this.isPersonActive = person.isActive; //cpf read only
    this.form.addControl('id', this.formBuilder.control(null));
    this.form.addControl('scPersonMaritalStatusId', this.formBuilder.control(null));
    this.form.addControl('scPersonTypeNationalityId', this.formBuilder.control(null));
    this.form.addControl('scPersonMatrimonialPropertyId', this.formBuilder.control(null));
    this.form.addControl('scPersonGenderId', this.formBuilder.control(null));
    this.form.addControl('scPersonSpouse', this.formBuilder.control(null));
    this.form.addControl('spouseId', this.formBuilder.control(null));
    this.form.patchValue({ id: person.id });

    if (person != undefined) {

      if (person.dateOfBirth !== undefined && person.dateOfBirth != null) {
        const dataNasc = formatDate(new Date(person.dateOfBirth), 'dd/MM/yyyy', 'pt-BR');
        this.form.patchValue({ dateOfBirth: dataNasc });
      }

      this.form.addControl('scPersonGenderId', person.scPersonGenderId);
      this.form.patchValue({ phone_number: person.telephone });
      this.form.patchValue({ mobile_number: person.mobile });
      this.form.addControl('scPersonTypeNationalityId', person.scPersonTypeNationalityId);
      this.form.addControl('scPersonMaritalStatusId', person.scPersonMaritalStatusId);
      this.form.addControl('scPersonMatrimonialPropertyId', person.scPersonMatrimonialPropertyId);

      this.form.patchValue(person);

      this.getAllUFLocal(person.scCountryId, 'person');
      this.getAllCitiesByUFLocal(person.scStateId, 'person');
    }

  }



  onSavePerson(payload: ScPerson): void {
    this.personService.updItemPersonalData(payload).subscribe({
      next: (res) => {
        if (res)
          this.messageService.messageSuccess(this.msgPrefix + this.translateService.instant('message.record_updated'));
      },
      error: (err) => {
        console.log(`${this.logComponent} - onSavePerson - Update : ${err}.`)
        this.message = this.translateService.instant('api_response.' + err.error);

        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
          this.message = this.translateService.instant('api_response.ERROR');

        this.messageService.messageError(this.msgPrefix + this.translateService.instant(this.message));
      }
    });
    this.loading = false;
  }

  onConfirm(): void {
    this.loading = true;
    const payload = this.form.value;

    let person: ScPersonOutput = {
      id: payload.id,
      name: payload.name,
      cpf: payload.cpf,
      gender: 0,
      telephone: payload.phone_number,
      mobile: payload.mobile_number,
      email: payload.email,
      fatherName: payload.fatherName,
      motherName: payload.motherName,
      maritalStatus: 0,
      dateOfBirth: payload.dateOfBirth,
      scCompanyId: this.userTokenService.getCompanyId(),
      scUserId: this.userTokenService.getUserId(),
      spouse: null,
      roles: null,

      spouseId: payload.spouseId,

      //   scPersonSpouseOutput: spouse,
      //   ScPersonSpouse: spouse,
      scPersonMaritalStatusId: payload.scPersonMaritalStatusId,
      profession: payload.profession,
      observation: payload.observation,
      scPersonTypeNationalityId: payload.scPersonTypeNationalityId,
      scPersonMatrimonialPropertyId: payload.scPersonMatrimonialPropertyId,
      scPersonGenderId: payload.scPersonGenderId,
      //scPersonScAddress: ScAddressScPerson[];
      scCountryId: payload.scCountryId,
      scStateId: payload.scStateId,
      scCityId: payload.scCityId
    };

    if (this.form.valid) {
      this.onSavePerson(person);
    }
    else {
      this.messageService.messageWarning(this.msgPrefix + this.translateService.instant('label.checkRequiredFields'));
      return;
    }
  }

  isFieldValid(field: string) {
    return !this.form.get(field)?.valid && this.form.get(field)?.touched;
  }

  isFieldValidDropdown(field: string) {
    return !this.form.get(field)?.valid;
  }

  reciverFeedbackPersonMaritalStatus(event) {
    if (event != null && event !== undefined && event.name.toLowerCase() === "casado") {

    }
    this.form.patchValue({ scPersonMaritalStatusId: event != null && event !== undefined ? event.id : null });
  }

  reciverFeedbackPersonTypeNationality(event) {
    this.form.patchValue({ scPersonTypeNationalityId: event });
  }

  reciverFeedbackPersonMatrimonialProperty(event) {
    this.form.patchValue({ scPersonMatrimonialPropertyId: event });
  }

  reciverFeedbackPersonGender(event) {
    this.form.patchValue({ scPersonGenderId: event });
  }

  reciverFeedbackCountry(countryId: string, type: string) {
    this.form.patchValue({ scCountryId: countryId });

  }

  reciverFeedbackState(stateId: string, type: string) {
    this.form.patchValue({ scStateId: stateId });
    this.getAllCitiesByUFLocal(stateId, type);
  }

  reciverFeedbackCity(cityId: string) {
    this.form.patchValue({ scCityId: cityId });
  }



  translate() {
    const sleep = (ms) => new Promise(r => setTimeout(r, ms));
    (async () => {
      await sleep(50);

      this.userId = this.userTokenService.getUserId();
      this.personId = this.userTokenService.getPersonId();
      this.msgPrefix = this.translateService.instant('label.myPersonalData') + ' - ';
      this.isRegister = false;

    })();
  }

  //   onGetPersonById(id: string): void {
  //     this.personService.getPersonById(this.language, id).subscribe({
  //       next: (response) => {
  //         this.onSetDataForm(response);
  //       },
  //     });
  //   }

  getAllCountryLocal() {
    this.loadListCountry = false;
    this.listCountry = [];
    this.countryService.getList().subscribe(
      {
        next: (res) => {
          if (res && res.data.length > 0) {
            this.listCountry = res.data.filter(f => f.name.toLowerCase() != 'brasil');

            if (res.data.filter(f => f.name.toLowerCase() == 'brasil').shift()) {
              let countryDefault = res.data.filter(f => f.name.toLowerCase() == 'brasil').shift();
              this.listCountry.unshift(countryDefault);
            }
          }
          this.loadListCountry = true;
        },
        error: (err) => {
          console.log(`${this.logComponent} - getAllCountryLocal : ${err}.`);
          this.loadListCountry = true;
        }
      });
  }

  getAllUFLocal(countryId: string, type: string) {
    this.listState = [];
    this.stateService.getListByCountry(countryId).subscribe(
      {
        next: (res) => {
          if (res && res.data.length > 0) {
            this.listState = res.data;
          }
          this.loadListState = true;
        },
        error: (err) => {
          console.log(`${this.logComponent} - getAllUFLocal : ${err}.`);
          this.loadListState = true;
        }
      });
  }

  getAllCitiesByUFLocal(stateId: string, type: string) {
    this.listCity = [];
    this.cityService.getListByState(stateId).subscribe(
      {
        next: (res) => {
          if (res && res.data.length > 0) {
            this.listCity = res.data.sort((a, b) => a.name.localeCompare(b.name));
          }
          this.loadListCity = true;
        },
        error: (err) => {
          console.log(`${this.logComponent} - getAllCitiesByUFLocal : ${err}.`);
          this.loadListCity = true;
        }
      });
  }

}
