import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserTokenService } from "src/app/core/services/user-token.service";

@Component({
    selector: 'notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss']
  })

  export class NotFoundComponent implements OnInit {



    constructor(private userTokenService: UserTokenService,
                private router: Router
     )
      { }

    ngOnInit(): void {



    }
}
