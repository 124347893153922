import { DropDown } from 'src/app/shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ScCityService } from 'src/app/core/services/sc/sc-city.service';



@Component({
    selector: 'sf-sc-city-dropdown',
    templateUrl: './sc-city-dropdown.component.html',
    styleUrls: ['./sc-city-dropdown.component.scss']
})

export class ScCityDropdownComponent implements OnInit, OnChanges {

    public list: DropDown[];
    @Input() placeHolderLanguage: string;
    public selectedItem: DropDown;

    @Input() scStateId: string;
    @Input() scCityId: string;
    @Input() languageCode: string = "pt-BR";
    @Input() disabled: boolean = false;
    @Output() choseItem = new EventEmitter();
    loading: boolean = false;

    constructor(private cityService: ScCityService) { }

    ngOnInit(): void {
        this.loading = true;
        this.getList();
    }

    ngOnChanges(): void {
        this.getList();
    }

    getList() {

        this.cityService.getDropDownList(this.scStateId).subscribe(
            {
                next: (res) => {
                    if (res)
                        this.list = res.data;
                    if (this.list !== null) {
                        this.selectedItem = this.list.find(x => x.id == this.scCityId);
                        // if (this.selectedItem == null || this.selectedItem.id == undefined)
                        // {
                        //     this.selectedItem = this.list[0];
                        // }
                    }
                    //this.feedback();
                    this.loading = false;
                },
                error: (err) => {
                    console.log(`CityDropDown - getList : ${err}.`);
                    this.loading = false;
                }
            });
    }

    feedback() {
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
            await sleep(150);
            this.choseItem.emit(this.selectedItem);

        })();
    }

}
