import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PaStripeCheckoutLinkInput, ScPaymentInput, ScPaymentItem, ScPaymentList, ScPaymentOutput } from './wo-order-payment.model';



@Injectable({
    providedIn: 'root'
  })

export class WoOrderPaymentService{

    private environmentUrl: string = '';


    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Order/Payment';
    }



//======================================================
//================== ORDER ===========================
//======================================================

    getItem(id: string,language: string): Observable<ScPaymentItem> {
        let url = this.environmentUrl+ id+"/"+language;
        return this.httpClient.get<ScPaymentItem>(url);
    }



    getList(language: string) : Observable<ScPaymentList>{
        let url = this.environmentUrl;
        return this.httpClient.get<ScPaymentList>(url);
    }

    addItem(model: ScPaymentOutput){
        let url = this.environmentUrl;

        //todo set input
        let input : ScPaymentInput = {amount: model.amount, status: 1, paymentType: 1, details: 'Pix: '+ model.pixValue.toString+' Card: '+model.cardValue.toString()+' Dinheiro: '+model.moneyValue.toString(), orderId: model.orderId};
        console.log(input);
        return this.httpClient.post(url, input);
    }

    updItem(model: ScPaymentOutput){
        let url = this.environmentUrl+ model.id;
        return this.httpClient.put(url, model);
    }

    cancelItem(id: string) {
        let url = this.environmentUrl+id;
        return this.httpClient.delete(url);
    }



//STRIPE
    generateStripeCheckoutLink(item: PaStripeCheckoutLinkInput){
        let url = this.environmentUrl+"/stripe/checkout/link";
        return this.httpClient.post<any>(url, item);
    }

}


