import { ScCityList } from './../../../models/response/sc-city.response';
import { Component, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDown } from 'src/app/shared/model/base-model';
import { ScAddressService } from 'src/app/core/services/sc/sc-address.service';
import { Address, ScAddressOutput } from 'src/app/models/response/sc-address.response';
import { SfMessageService } from 'src/app/core/services/core/sf-message.service';
import { ScCityService } from 'src/app/core/services/sc/sc-city.service';

@Component({
  selector: 'sf-address-detail',
  templateUrl: './sc-address-detail.component.html',
  styleUrls: ['./sc-address-detail.component.scss']
})
export class ScAddressDetailComponent implements OnInit, OnChanges {

  @Input() public addressId: string = "";
  @Input() public item: ScAddressOutput = {};
  @Output() newAddressEmitter = new EventEmitter<string>();
  @Output() formattedAddressEmitter = new EventEmitter<string>();


  public cepWasSubmitted: boolean = false;
  public addressForm: FormGroup;
  public zipCodeMask: string = "";
  public message: string = "";
  public cityList: DropDown[];

  constructor(  private addressService: ScAddressService,
                private cityService: ScCityService,
                private translateService: TranslateService,
                private messageService: SfMessageService,
                private formBuilder: FormBuilder
            ) {}



  ngOnInit(): void {

    const language = this.translateService.currentLang

    if (language == "pt-BR") {
      this.zipCodeMask = "99999-999"
    }
    else if (language == "en-US") {
      this.zipCodeMask = "99999"
    }
    this.setInput();
  }

  ngOnChanges(): void {
      this.setInput();
  }

  setInput(){
    this.buildForm();

    if (this.item != null && this.item != undefined && this.item.id != null && this.item.id != undefined)  {
        this.formattedAddressEmitter.emit(this.item.formattedAddress)
        this.setForm();
    }
    else if (this.addressId != null && this.addressId != ""){
        this.getitem(this.addressId);
    }
  }

  buildForm() {
    this.addressForm = this.formBuilder.group({
        postalCode: ['', Validators.required],
        state: [''],
        city: ['', Validators.required],
        neighborhood: ['', Validators.required],
        streetNumber: ['', Validators.required],
        streetComplement: [''],
        route: ['', Validators.required],
        sublocality: [''],
        adimistrativeAreaLevel2: [''],
        adimistrativeAreaLevel1: [''],
        country: [''],
        formattedAddress: [''],
        lat: [''],
        lng: [''],
        scCityId: [''],
        scStateId: ['']
      });
  }


  private setForm() {
    this.addressForm.setValue({
      ...this.addressForm.value,
      state: this.item.state,
      city: this.item.city.toLocaleUpperCase(),
      neighborhood: this.item.neighborhood,
      route: this.item.route,
      streetNumber: this.item.streetNumber,
      streetComplement: this.item.streetComplement,
      postalCode: this.item.postalCode,
      formattedAddress: this.item.formattedAddress,
      scCityId: this.item.scCityId,
      scStateId: this.item.scStateId,
    })



  }

  getitem(id: string) {
    this.addressService.getItem(id).subscribe(
      {
        next: (res) => {
            if (res)
                this.item = res;
            this.formattedAddressEmitter.emit(this.item.formattedAddress)

            this.setForm();
        },
        error: (err) => {
            console.log(`ScAddressDetailComponent - getitem : ${err}.`);
            this.messageService.messageError(this.translateService.instant('api_response.ERROR'));
        }
      });
  }


  reciverFeedbackState(item: DropDown) {
    if (item != null && item.id != undefined && (item.id != null)) {
        this.item = { ...this.item, state: item.id, scStateId: item.id }
        this.addressForm.setValue({ ...this.addressForm.value, state: item.name, scStateId: item.id })
    }
  }
  reciverFeedbackCity(item: DropDown) {
    if (item != null && item.id != undefined && item.id != null) {
        this.item.scCityId = item.id;
        this.item.city = item.name;
        this.addressForm.setValue({ ...this.addressForm.value, city: item.name, scCityId: item.id })
        // console.log('reciverFeedbackCity')
        // console.log(this.item.scCityId);
        // console.log(this.item.city);
        // console.log(this.addressForm.value);
    }
  }



  public changeZipCode(event: string) {
    if (event != this.item.postalCode) {
      this.cepWasSubmitted = false;
    }
    this.item = { ...this.item, postalCode: event }

  }

  async consultaCEP(cep) {
    // Nova variável "cep" somente com dígitos.
    console.log(cep)
    cep = cep.replace(/\D/g, '');

    if (cep != null && cep !== '') {
      try {
        const value = await this.addressService.findCEP(cep)
        console.log(value)
        //this.consultaUF(value.state)
        this.item = {
          ...this.item, postalCode: value.postalCode, state: value.state, city: value.city.toLocaleUpperCase(), neighborhood: value.neighborhood, reference: value.reference, streetNumber: value.streetNumber, route: value.route, sublocality: value.sublocality, adimistrativeAreaLevel2: value.adimistrativeAreaLevel2, adimistrativeAreaLevel1: value.adimistrativeAreaLevel1, country: value.country, formattedAddress: value.formattedAddress, lat: value.lat, lng: value.lng
        }

        console.log(this.item)
        this.addressForm.setValue({
          ...this.addressForm.value,
          state: value.state,
          city: value.city.toLocaleUpperCase(),
          neighborhood: value.neighborhood,
          route: value.route,
          formattedAddress: value.formattedAddress,
        })

        console.log(this.addressForm.value)
        this.cepWasSubmitted = true;
        if (value.city == null) {
          this.item = {}

          this.cepWasSubmitted = false;
        }
      } catch (error) {
        this.item = {}
        this.cepWasSubmitted = false;
      }


    }
  }




  save() {

    const address : Address = {
      ...this.item,
      postalCode: this.addressForm.value.postalCode,
      state: this.addressForm.value.state,
      city: this.addressForm.value.city,
      neighborhood: this.addressForm.value.neighborhood,
      streetNumber: this.addressForm.value.streetNumber,
      streetComplement: this.addressForm.value.streetComplement,
      route: this.addressForm.value.route,
      sublocality: this.addressForm.value.sublocality,
      adimistrativeAreaLevel2: this.addressForm.value.adimistrativeAreaLevel2,
      adimistrativeAreaLevel1: this.addressForm.value.adimistrativeAreaLevel1,
      country: this.addressForm.value.country,
      lat: this.addressForm.value.lat,
      lng: this.addressForm.value.lng
    }

    if (address.id != null && address.id != "") {
        this.addressService
        .updUser(address)
        .subscribe(
            {
                next: (res) => {
                    this.messageService.messageSuccess(this.translateService.instant('message.record_updated'));
                    this.newAddressEmitter.emit(res.id);
                    this.formattedAddressEmitter.emit(res.formattedAddress);
                },
                error: (err) => {
                    console.log(`ScAddressDetailComponent - save - Update : ${err}.`)
                    this.message = this.translateService.instant('api_response.'+err.error);

                    if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                        this.message = this.translateService.instant('api_response.ERROR');

                    this.messageService.messageError(this.translateService.instant(this.message));
                }
            });
    }
    else{
        this.addressService
            .add(address)
            .subscribe({
                next: (res) => {
                    this.messageService.messageSuccess(this.translateService.instant('message.record_inserted'));
                    this.newAddressEmitter.emit(res.id);
                    this.formattedAddressEmitter.emit(res.formattedAddress);
                },
                error: (err) => {
                    console.log(`ScAddressDetailComponent - save - Insert : ${err}.`)
                    this.message = this.translateService.instant('api_response.'+err.error);

                    if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                        this.message = this.translateService.instant('api_response.ERROR');

                    this.messageService.messageError(this.translateService.instant(this.message));

                }
            });
    }
  }


cancel()
{
    this.newAddressEmitter.emit("");
}


  isFieldValid(field: string) {
    return !this.addressForm.get(field).valid && this.addressForm.get(field).touched;
  }

  translate()
  {


      const sleep = (ms) => new Promise(r => setTimeout(r, ms));
      (async () => {
           await sleep(50);


       })();




  }

}
