import { AuthService } from 'src/app/core/auth/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';


import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import localePT from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';



import { InputMaskModule } from 'primeng/inputmask';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';

import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { InputNumberModule } from 'primeng/inputnumber';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';
import { TableModule } from 'primeng/table';
import { DividerModule } from 'primeng/divider';
import { SharedModule } from 'src/app/shared/shared.module';
import { SfBreadCrumbModule } from 'src/app/shared/components/sf-breadcrumb/sf-breadcrumb.module';
import { SfSubHeaderModule } from 'src/app/shared/components/sub-header/sub-header.module';
import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { ScAddressRoutingModule } from './sc-address.routing.module';
import { ScAddressGridComponent } from './sc-address-grid/sc-address-grid.component';
import { DataViewModule } from "primeng/dataview";
import { ProgressSpinnerModule } from 'primeng/progressspinner'; // Import ProgressSpinnerModule if not already imported
import {ToolbarModule} from 'primeng/toolbar';
import { ScAddressDetailComponent } from './sc-address-detail/sc-address-detail.component';
import { ScCityDropdownComponent } from './sc-city-dropdown/sc-city-dropdown.component';
import { ScStateDropdownComponent } from './sc-state-dropdown/sc-state-dropdown.component';
import { ScCountryDropdownComponent } from './sc-country-dropdown/sc-country-dropdown.component';
import { ScAddressService } from 'src/app/core/services/sc/sc-address.service';





registerLocaleData(localePT, 'pt', localeExtraPT);

const components = [
    ScAddressGridComponent,
    ScAddressDetailComponent,
    ScCityDropdownComponent,
    ScStateDropdownComponent,
    ScCountryDropdownComponent
];

@NgModule({
    declarations: [components],
    exports: [components],
    imports: [FormsModule,
            FormsModule,
            ReactiveFormsModule,
            CommonModule,
            RouterModule,
            ButtonModule,
            InputTextModule,
            InputMaskModule,
            TranslateModule,
            MessageModule,
            MessagesModule,
            TableModule,
            TooltipModule,
            ConfirmDialogModule,
            LoaderModule,
            CardModule,
            DialogModule,
            NotificationsModule,
            DropdownModule,
            InputNumberModule,
            DividerModule,
            SharedModule,
            SfBreadCrumbModule,
            SfSubHeaderModule,
            SfDropDownModule,
            ScAddressRoutingModule,
            DataViewModule,
            ProgressSpinnerModule,
            ToolbarModule

        ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, ScAddressService, {provide: LOCALE_ID, useValue: 'pt'}]
})
export class ScAddressModule { }


