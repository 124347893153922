<sf-loader></sf-loader>
<sf-notification></sf-notification>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="formgrid grid">
    <div class="col-12 sm:col-12 md:col-12 lg:col-6">
        <sf-sub-header
            [title]="'label.myPersonalData' | translate"
            [description]="'label.MyPersonalDataDescription' | translate">
        </sf-sub-header>
    </div>
    <div class="col-12 sm:col-12 md:col-12 lg:col-6">
        <sf-breadcrumb [model]="BREAD_CRUMB_LIST"></sf-breadcrumb>
    </div>
</div>

<p-divider></p-divider>


<div class="formgrid grid">

    <!-- === User photo & name === -->
    <div class="field col-12 sm:col-12 md:col-12 lg:col-3 xl:col-3">
        <p-card>
            <div class="col-12">
                <div class="p-field center-img flex align-items-center justify-content-center">
                    <div class="avatar-container">
                        <div class="image-container" [ngStyle]="{backgroundImage: 'url(' + item.pictureUri+ ')'}" *ngIf="hasPicture"></div>
                        <div class="image-container" [ngStyle]="{backgroundImage: 'url(assets/layout/images/perfil.svg)'}" *ngIf="!hasPicture"></div>
                        <label for="avatar-input-file">
                            <div class="icon-wrapper">
                                <input type="file" id="avatar-input-file" (change)="fileChangeEvent($event)" accept="image/*"/>
                                <i class="pi pi-upload icon"></i>
                            </div>
                        </label>
                    </div>
                </div>

                <div class="p-field flex align-items-center justify-content-center font-medium text-3xl" >
                    {{item.name}}
                </div>

                <div class="grid mt-3">
                    <div class="col-5 text-center font-bold label-detail text-xl"><span translate>label.login</span></div>
                    <div class="col ml-1 text-xl">{{item.login}}</div>
                </div>

                <div class="grid mt-3">
                    <div class="col-5 text-center font-bold label-detail text-xl"><span translate>label.email</span></div>
                    <div class="col ml-1 text-xl">{{item.email}}</div>
                </div>

                <div class="grid mt-3">
                    <div class="col-5 text-center font-bold label-detail text-xl"><span translate>label.role</span></div>
                    <div class="col ml-1 text-xl">{{item.scRoleName}}</div>
                </div>

                <div class="grid mt-3">
                    <div class="col-5 text-center font-bold label-detail text-xl"><span translate>label.phone_number</span></div>
                    <div class="col ml-1 text-xl" *ngIf="item.scPerson != null">{{item.scPerson.telephone}}</div>
                </div>

                <div class="grid mt-3">
                    <div class="col-5 text-center font-bold label-detail text-xl"><span translate>label.mobile_number</span></div>
                    <div class="col ml-1 text-xl" *ngIf="item.scPerson != null">{{item.scPerson.mobile}}</div>
                </div>

                <div class="grid mt-3">
                    <div class="col-5 text-center font-bold label-detail text-xl"><span translate>label.language</span></div>
                    <div class="col ml-1 text-xl">{{item.languageCode}}</div>
                </div>

                <div class="grid mt-3">
                    <div class="col-5 text-center font-bold label-detail text-xl"><span translate>label.status</span></div>
                    <div class="col ml-1 text-xl">
                        <span [style]="{'color':''+item.scUserStatusColor+''}">{{item.scUserStatusName}}</span>
                    </div>
                </div>
                <div class="grid mt-3">
                    <div class="col-5 text-center font-bold label-detail text-xl"><span translate>label.createdAt</span></div>
                    <div class="col ml-1 text-xl">{{item.createdAt |  date:'dd/MM/yyyy HH:mm:ss'}}</div>
               </div>
               <div class="grid mt-3">
                <div class="col-5 text-center font-bold label-detail text-xl"><span translate>label.updatedAt</span></div>
                    <div class="col ml-1 text-xl">{{item.updatedAt |  date:'dd/MM/yyyy HH:mm:ss'}}</div>
                </div>
                <div class="grid mt-3 mb-2">
                    <div class="col-5 text-center font-bold label-detail text-xl"><span translate>label.login_last_date</span></div>
                    <div class="col ml-1 text-xl">{{item.createdAt |  date:'dd/MM/yyyy HH:mm:ss'}}</div>
                </div>
            </div>
        </p-card>
    </div>

    <!-- === User data === -->

    <div class="col-12 sm:col-12 md:col-12 lg:col-9 xl:col-9">
        <p-card>
            <div class="grid">
                <div class="col">
                    <div class="text-right">
                        <button pButton pRipple icon="pi pi-times" style="width: 120px; margin: 3px;" class="p-button-danger p-mr-2 font-medium text-xl" [label]="'button.cancel' | translate" (click)="cancel();" ></button>
                        <button pButton pRipple icon="pi pi-save" style="width: 120px; margin: 3px;" class="p-button-success p-mr-2 font-medium text-xl" [label]="'button.save' | translate" (click)="save();" ></button>
                    </div>
                </div>
            </div>
            <div style="min-height: 625px;">
                <p-tabView styleClass="tabview-custom">
                    <p-tabPanel>
                        <ng-template pTemplate="header" >
                            <div class="flex align-items-center gap-2">
                                <p-avatar
                                    image="https://th.bing.com/th/id/OIP.R87PbOkdc695AAZ-_qrLYwHaHk?rs=1&pid=ImgDetMain"
                                    shape="circle" />
                                <span class="font-bold text-xl white-space-nowrap ml-2" translate>label.user_details</span>
                            </div>
                        </ng-template>
                        <ng-container>
                          <sc-user-detail [id]="userId"></sc-user-detail>
                        </ng-container>
                    </p-tabPanel>

                    <p-tabPanel >
                        <ng-template pTemplate="header" >
                            <div class="flex align-items-center gap-2">
                                <p-avatar
                                    image="https://static.vecteezy.com/system/resources/previews/000/423/362/original/vector-documents-icon.jpg"
                                    shape="circle" />
                                    <span class="font-bold text-xl white-space-nowrap ml-2" translate>label.myPersonalData</span>
                            </div>
                        </ng-template>
                        <div class="card">
                            <sc-person-full-client  [id]="userId"></sc-person-full-client>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </p-card>
    </div>
</div>



<p-dialog [(visible)]="userPicDialog" [style]="{width: '480px',height: '600px'}" [header]="'label.user_upload_avatar' | translate" [modal]="true" styleClass="p-fluid" >
<ng-template pTemplate="content">
    <div style="margin-top:1px;" class="grid">
        <div class="col-12 p-field flex align-items-center justify-content-center font-bold" style="width: 100%;" >
            <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [containWithinAspectRatio]="containWithinAspectRatio"
                [aspectRatio]="1"
                [resizeToWidth]="128"
                [cropperMinWidth]="128"
                [roundCropper]="true"
                [canvasRotation]="canvasRotation"
                [alignImage]="'center'"
                [style.display]="showCropper ? null : 'none'"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady($event)"
                (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>
    </div>
</ng-template>

<ng-template pTemplate="footer">
    <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="hidePicDialog()"></button>
    <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="savePic()" [disabled]="!showCropper"></button>
</ng-template>
</p-dialog>
