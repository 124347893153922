<div id="layout-right-sidebar" [ngClass]="{'layout-right-sidebar-active': app.rightPanelActive}"
     (click)="app.onRightPanelClick()">
    <div class="right-panel-scroll-content">
        <p-tabView>
            <p-tabPanel leftIcon="pi pi-cog">
                <div class="submenu-title">
                    <span>Settings</span>
                </div>



                <div class="submenu-content settings">

                    <div class="col-12">
                        <span translate styleClass="p-m-1 mr-1">menu.choose_language</span>
                        <div class="flex flex-row flex-wrap card-container blue-container">
                            <div class="flex align-items-center justify-content-center m-2">
                                <span class="flag flag-br" style="width: 30px; height: 20px" (click)="changeLanguage('pt-BR')" style="margin-left: 5px; margin-top: 10px;" ></span>
                            </div>
                            <div class="flex align-items-center justify-content-center m-2">
                                <span class="flag flag-gb" style="width: 30px; height: 20px" (click)="changeLanguage('en-US')" style="margin-left: 5px; margin-top: 10px;"></span>
                            </div>
                            <div class="flex align-items-center justify-content-center m-2">
                                <span class="flag flag-pt" style="width: 30px; height: 20px" (click)="changeLanguage('pt-PT')" style="margin-left: 5px; margin-top: 10px;"></span>
                            </div>
                        </div>




                </div>


                    <div class="grid">

                        <div class="order-settings col-6">
                            <i class="pi pi-plus"></i>
                            <span>NEW ORDERS</span>
                        </div>
                        <div class="sales-settings col-6">
                            <i class="pi pi-chart-line"></i>
                            <span>TOTAL SALES</span>
                        </div>
                        <div class="shipment-settings col-6">
                            <i class="pi pi-compass"></i>
                            <span>PENDING SHIPMENTS</span>
                        </div>
                        <div class="visitors-settings col-6">
                            <i class="pi pi-user"></i>
                            <span>UNIQUE VISITORS</span>
                        </div>
                        <div class="history-settings col-6">
                            <i class="pi pi-refresh"></i>
                            <span>SALES HISTORY</span>
                        </div>
                        <div class="leads-settings col-6">
                            <i class="pi pi-users"></i>
                            <span>ALL LEADS</span>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel leftIcon="pi pi-inbox">
                <div class="submenu-title">
                    <span>Inbox</span>
                </div>
                <div class="submenu-content messages">

                    <app-chatbox></app-chatbox>

                    <ul>
                        <li>
                            <div>
                                <img src="assets/layout/images/avatar-john.png" alt="california-layout"/>
                                <span class="time-stamp">1m</span>
                            </div>
                            <span class="messager-name">John Swisher</span>
                            <span class="message-text">Ab nobis, magnam sunt eum. Laudantium…</span>
                        </li>
                        <li>
                            <div>
                                <img src="assets/layout/images/avatar-warren.png" alt="california-layout"/>
                                <span class="time-stamp">33m</span>
                            </div>
                            <span class="messager-name">Warren Shaw</span>
                            <span class="message-text">Ab nobis, magnam sunt eum. Laudantium…</span>
                        </li>
                        <li>
                            <div>
                                <img src="assets/layout/images/avatar-brenda.png" alt="california-layout"/>
                                <span class="time-stamp">2h</span>
                            </div>
                            <span class="messager-name">Brenda Soto</span>
                            <span class="message-text">Ab nobis, magnam sunt eum. Laudantium…</span>
                        </li>
                        <li>
                            <div>
                                <img src="assets/layout/images/avatar-arlene.png" alt="california-layout"/>
                                <span class="time-stamp">1d</span>
                            </div>
                            <span class="messager-name">Arlene Welch</span>
                            <span class="message-text">Ab nobis, magnam sunt eum. Laudantium…</span>
                        </li>
                    </ul>
                </div>
            </p-tabPanel>
            <p-tabPanel leftIcon="pi pi-calendar">
                <div class="submenu-title">
                    <span>Events</span>
                </div>
                <div class="submenu-content events">
                    <ul>
                        <li>
                            <div class="calendar-event-date">
                                <span class="date">21</span>
                                <span class="date">OCT</span>
                            </div>
                            <div class="calendar-event-detail">
                                <span class="calendar-event-title">Project Meeting</span>
                                <i class="material-icons  pi pi-map-marker"></i>
                                <span class="calendar-event-location">Chicago</span>
                            </div>
                        </li>

                        <li>
                            <div class="calendar-event-date">
                                <span class="date">13</span>
                                <span class="date">NOV</span>
                            </div>
                            <div class="calendar-event-detail">
                                <span class="calendar-event-title">Presentation 1</span>
                                <i class="material-icons pi pi-map-marker"></i>
                                <span class="calendar-event-location">New York</span>
                            </div>
                        </li>
                        <li>
                            <div class="calendar-event-date">
                                <span class="date">01</span>
                                <span class="date">DEC</span>
                            </div>
                            <div class="calendar-event-detail">
                                <span class="calendar-event-title">Presentation 2</span>
                                <i class="material-icons pi pi-map-marker"></i>
                                <span class="calendar-event-location">New York</span>
                            </div>
                        </li>
                        <li>
                            <div class="calendar-event-date">
                                <span class="date">25</span>
                                <span class="date">DEC</span>
                            </div>
                            <div class="calendar-event-detail">
                                <span class="calendar-event-title">New Year Party</span>
                                <i class="material-icons pi pi-map-marker"></i>
                                <span class="calendar-event-location">San Francisco</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
