import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserTokenService } from "src/app/core/services/user-token.service";

@Component({
    selector: 'dashboard-home',
    templateUrl: './dashboard-home.component.html',
    styleUrls: ['./dashboard-home.component.scss']
  })

  export class DashboardHomeComponent implements OnInit {



    constructor(private userTokenService: UserTokenService,
                private router: Router
     )
      { }

    ngOnInit(): void {


        console.log(this.userTokenService.hasPermissionArray(['HOME_CARTORIO_CLIENT']));
        console.log(this.userTokenService.hasPermissionArray(['HOME_CARTORIO_SERVICE']));

        if (this.userTokenService.hasPermissionArray(['HOME_CARTORIO_CLIENT'])){
            console.log('ok1');
            this.router.navigate(['/home-c-client']);
        }

        else if (this.userTokenService.hasPermissionArray(['HOME_CARTORIO_SERVICE'])){
            console.log('ok2');
            this.router.navigate(['/home-c-service']);
        }
        else if (this.userTokenService.hasPermissionArray(['HOME_CARTORIO_ADMIN'])){
            console.log('ok3');
            this.router.navigate(['/home-c-admin']);
        }

        console.log('ok');
    }
}
