import { Component } from '@angular/core';

@Component({
    selector: 'app-chatbox',
    templateUrl: './chatbox.component.html',
    styleUrls: ['./chatbox.component.scss']
})
export class ChatboxComponent {
    messages: { user: string, text: string }[] = [];
    newMessage: string = '';

    sendMessage() {
        if (this.newMessage.trim()) {
            this.messages.push({ user: 'User', text: this.newMessage });
            this.newMessage = '';
        }
    }
}