<form [formGroup]="addressForm" (submit)="save()">
    <div class="formgrid grid text-lg">
        <div class="col-12 md:col-6 lg:col-6 xl:col-4">
            <div class="field mb-2">
                <label for="zip" class="mb-1 font-bold text-xl">
                    <span translate>label.zip_code</span>*
                </label>
                <p-inputMask
                    inputId="zip"
                    formControlName="postalCode"
                    [mask]="zipCodeMask"
                    [placeholder]="zipCodeMask"
                    autoWidth="false"
                    (ngModelChange)="changeZipCode($event)"
                    styleClass="text-xl w-full">
                </p-inputMask>

            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-8 vertical-center">
            <div class="field mb-2 search-button">
                <p-button
                    icon="pi pi-search"
                    severity="info"
                    (click)="consultaCEP(addressForm.get('postalCode').value)"
                    class="text-xl font-semibold">
                </p-button>
            </div>
        </div>
        <!-- <div class="field col-12 md:col-3 lg:col-3 xl:col-3">
    <div class="field mb-2">
        <label for="country" class="mb-1 font-bold text-lg"><span translate>label.country</span></label>
        <sf-dropdown-generic InputId="country" [list]="companyStatusList" [id]="item.scCompanyStatusId" (selectedItemOutput)="reciverFeedbackCompanyStatus($event)" [disabled]="true" ></sf-dropdown-generic>
    </div>
</div> -->

        <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
            <div class="field mb-2">
                <label for="route" class="mb-1 font-bold text-xl">
                    <span translate>label.street</span>*
                </label>
                <input pInputText
                    type="text"
                    formControlName="route"
                    id="route"
                    class="text-xl w-full"
                >
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-3 xl:col-3">
            <div class="field mb-2">
                <label for="street_number" class="mb-1 font-bold text-xl">
                    <span translate>label.street_number</span>*
                </label>
                <input pInputText
                    formControlName="streetNumber"
                    type="text"
                    id="street_number"
                    class="text-xl w-full"
                >
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-3 xl:col-3">
            <div class="field mb-2">
                <label for="street_complement" class="mb-1 font-bold text-xl">
                    <span translate>label.street_complement</span>
                </label>
                <input  pInputText
                    type="text"
                    formControlName="streetComplement"
                    id="street_complement"
                    class="text-xl w-full"
                >
            </div>
        </div>
        <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
            <div class="field mb-2">
                <label for="neighborhood" class="mb-1 font-bold text-xl">
                    <span translate>label.neighborhood</span>*
                </label>
                <input pInputText
                    type="text"
                    formControlName="neighborhood"
                    id="neighborhood"
                    class="text-xl w-full"
                >
            </div>
        </div>

        <div class="field col-12 md:col-6 lg:col-6 xl:col-6 ">
            <div class="field mb-2">
                <label for="state" class="mb-1 font-bold text-lg"><span translate>label.state</span>*</label>
                <sf-sc-state-dropdown
                    inputId="state"
                    [scCountryId]="item.country"
                    [scStateId]="item.scStateId"
                    (choseItem)="reciverFeedbackState($event)"
                    [disabled]="item.state == null ? true : false || cepWasSubmitted"
                    [style]="{'width':'100%'}">
                </sf-sc-state-dropdown>
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-6 xl:col-6 ">
            <div class="field mb-2">
                <label for="scCityId" class="mb-1 font-bold text-lg"><span translate>label.city</span>*</label>
                <sf-sc-city-dropdown
                    inputId="scCityId"
                    [scStateId]="item.scStateId"
                    [scCityId]="item.scCityId"
                    (choseItem)="reciverFeedbackCity($event)"
                    [disabled]="item.state == null ? true : false || cepWasSubmitted"
                    [style]="{'width':'100%'}">
                </sf-sc-city-dropdown>
            </div>
        </div>

        <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
            <div class="field mb-2">
                <label class="mb-1 font-bold text-xl"><span translate>label.address</span>:</label>
                <span class="ml-1 font-semibold text-xl">{{ addressForm.get('formattedAddress').value }}</span>
            </div>
        </div>

        <!-- <div class="field col-12 md:col-3 lg:col-3 xl:col-3">
            <div style="float: right;" class="field mb-2 search-button">
                <p-button [label]="'button.get_coordinate' | translate" severity="info" disabled="true"></p-button>
            </div>
        </div> -->
        <!-- <div class="field col-12 md:col-6 lg:col-3 xl:col-4 ">
            <div class="field mb-2">
                <label for="lat" class="mb-1 font-bold text-lg"><span translate>label.latitude</span></label>
                <input type="text" id="lat" pInputText
                    style="width: 100%;" disabled="true">
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-3 xl:col-4 ">
            <div class="field mb-2">
                <label for="lng" class="mb-1 font-bold text-lg"><span translate>label.longitude</span></label>
                <input type="text" id="lng" pInputText
                    style="width: 100%;" disabled="true">
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-6 xl:col-4">
            <div style="float: right;">
                <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;"
                    class="p-button-primary p-mr-2 mt-3" [label]="'button.confirm' | translate"
                    disabled="true"></button>
            </div>
        </div> -->
        <div class="field col-12 mt-5">
            <div class="grid">
                <div class="col">
                    <div class="text-right">
                        <button pButton pRipple
                            type="button"
                            icon="pi pi-times"
                            style="width: 120px; margin: 3px;"
                            class="p-button-warning p-mr-2 font-medium text-xl"
                            [label]="'button.cancel' | translate"
                            (click)="cancel();">
                        </button>
                        <button pButton pRipple
                            type="submit"
                            icon="pi pi-save"
                            style="width: 120px; margin: 3px;"
                            class="p-button-success p-mr-2 font-medium text-xl"
                            [label]="'button.save' | translate">
                        </button>
                    </div>
                </div>
            </div>
            <!-- <div style="float: right;">
                <button pButton type="submit" pRipple icon="pi pi-check" style="width: 110px; margin: 3px;"
                    class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" [disabled]="!addressForm.valid"></button>
            </div> -->
        </div>
    </div>

</form>
