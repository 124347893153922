
<form class="form" [formGroup]="form">
    <div class="col-12 text-xl">
        <div class="formgrid grid">
            <div class="col-12 sm:col-6 md:col-6 lg:col-3">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="ssn">
                        <span translate>label.person_ssn</span>
                    </label>
                    <p-inputMask
                        mask="999.999.999-99"
                        formControlName="cpf"
                        placeholder="999.999.999-99"
                        id="cpf"
                        inputId="cpf"
                        styleClass="mask p-inputtext-lg"
                        [style]="{'width':'100%'}"
                    ></p-inputMask>
                </div>
            </div>
            <div class="col-12 sm:col-6 md:col-6 lg:col-9">
                <div class="form__item text-right">

                </div>
            </div>
        </div>

        <div class="formgrid grid">
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="name">
                        <span translate>label.name</span>
                    </label>
                    <input pInputText
                        class="w-full text-xl"
                        formControlName="name"
                        id="name"
                        type="text"
                    />
                    <small class="p-invalid" *ngIf="isFieldValid('name')">
                        <span translate>label.field_name_required</span>
                    </small>
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="email">
                        <span translate>label.email</span>
                    </label>
                    <div class="col text-xl">{{this.form.get('email').value}}</div>
                    <!-- <input pInputText
                        class="w-full text-xl"
                        formControlName="email"
                        id="email"
                        type="text"
                    />
                    <small class="p-invalid" *ngIf="isFieldValid('email')">
                        <span translate>label.field_email_required</span>
                    </small> -->
                </div>
            </div>
        </div>

        <div class="formgrid grid">
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="dateOfBirth">
                        <span translate>label.date_of_birth</span>
                    </label>
                    <div class="flex-auto p-fluid">
                        <p-calendar
                            formControlName="dateOfBirth"
                            id="dateOfBirth"
                            [showIcon]="true"
                            inputId="buttondisplay"
                            [showOnFocus]="false"

                        />
                    </div>
                    <!-- <small class="p-invalid" *ngIf="isFieldValid('dateOfBirth')">
                        <span translate>label.field_date_of_birth_required</span>
                    </small> -->
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="scPersonGenderId">
                        <span translate>label.gender</span>
                    </label>
                    <ng-container *ngIf="isLoaded">
                        <sf-sc-person-gender-dropdown
                            [id]="form.get('scPersonGenderId').value"
                            (chosedItem)="reciverFeedbackPersonGender($event)"
                            [placeHolderLanguage]="'label.gender' | translate">
                        </sf-sc-person-gender-dropdown>
                        <small class="p-invalid" *ngIf="isFieldValidDropdown('scPersonGenderId')">
                            <span translate>label.field_gender_required</span>
                        </small>
                    </ng-container>
                    <ng-container *ngIf="!isLoaded">
                        <span class="container__list--loader">Carregando...</span>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" for="phone_number">
                        <span translate>label.phone_number</span>
                    </label>
                    <p-inputMask
                        mask="(99)9?99999999"
                        formControlName="telephone"
                        placeholder="(99)999999999"
                        inputId="phone_number"
                        styleClass="mask p-inputtext-lg"
                        [style]="{'width':'100%'}">
                    </p-inputMask>
                    <!-- <small class="p-invalid" *ngIf="isFieldValid('phone_number')">*</small> -->
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" for="mobile_number">
                        <span translate>label.mobile_number</span>
                    </label>
                    <p-inputMask
                        mask="(99)9?99999999"
                        formControlName="mobile"
                        placeholder="(99)99999999"
                        inputId="mobile_number"
                        styleClass="mask p-inputtext-lg"
                        [style]="{'width':'100%'}">
                    </p-inputMask>
                    <!-- <small class="p-invalid" *ngIf="isFieldValid('mobile_number')">*</small> -->
                </div>
            </div>
        </div>

        <div class="formgrid grid">
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="scPersonTypeNationalityId">
                        <span translate>label.type_nationality</span>
                    </label>
                    <ng-container *ngIf="isLoaded">
                        <sf-sc-person-type-nationality-dropdown
                            [id]="form.get('scPersonTypeNationalityId').value"
                            (chosedItem)="reciverFeedbackPersonTypeNationality($event)"
                            [placeHolderLanguage]="'label.type_nationality' | translate">
                        </sf-sc-person-type-nationality-dropdown>
                    </ng-container>
                    <ng-container *ngIf="!isLoaded">
                        <span class="container__list--loader">Carregando...</span>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="scCountryId"><span
                            translate>label.nationality</span></label>
                    <ng-container *ngIf="isLoaded">
                        <sf-dropdown-generic *ngIf="loadListCountry" InputId="scCountryId" [list]="listCountry"
                            [id]="form.get('scCountryId').value" (selectedItemOutput)="reciverFeedbackCountry($event, 'person')"
                            [filter]="true">
                        </sf-dropdown-generic>
                    </ng-container>
                    <ng-container *ngIf="!isLoaded">
                        <span class="container__list--loader" translate>label.loading</span>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="scStateId">
                        <span translate>label.birth_state</span>
                    </label>
                    <ng-container *ngIf="isLoaded">
                        <sf-dropdown-generic *ngIf="loadListState"
                            InputId="scStateId"
                            [list]="listState"
                            [id]="form.get('scStateId').value"
                            (selectedItemOutput)="reciverFeedbackState($event, 'person')"
                            [filter]="true">
                        </sf-dropdown-generic>
                        <!-- <small class="p-invalid" *ngIf="isFieldValidDropdown('scStateId')">
                            <span translate>label.field_birth_state_required</span>
                        </small> -->
                    </ng-container>
                    <ng-container *ngIf="!isLoaded">
                        <span class="container__list--loader">Carregando...</span>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="scCityId">
                        <span translate>label.birth_city</span>
                    </label>
                    <ng-container *ngIf="isLoaded">
                        <sf-dropdown-generic *ngIf="loadListCity"
                            InputId="birthCity"
                            [list]="listCity"
                            [id]="form.get('scCityId').value"
                            (selectedItemOutput)="reciverFeedbackCity($event)"
                            [filter]="true">
                        </sf-dropdown-generic>
                        <small class="p-invalid" *ngIf="isFieldValidDropdown('scCityId')">
                            <span translate>label.field_birth_city_required</span></small>
                    </ng-container>
                    <ng-container *ngIf="!isLoaded">
                        <span class="container__list--loader">Carregando...</span>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="formgrid grid">
            <div class="col-12 sm:col-6 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="scPersonMaritalStatusId">
                        <span translate>label.marital_status</span>
                    </label>
                    <ng-container *ngIf="isLoaded">
                        <sf-sc-person-marital-status-dropdown
                            [id]="form.get('scPersonMaritalStatusId').value"
                            (chosedItem)="reciverFeedbackPersonMaritalStatus($event)"
                            [placeHolderLanguage]="'label.marital_status' | translate">
                        </sf-sc-person-marital-status-dropdown>
                        <small class="p-invalid" *ngIf="isFieldValidDropdown('scPersonMaritalStatusId')">
                            <span translate>label.field_marital_status_required</span>
                        </small>
                    </ng-container>
                    <ng-container *ngIf="!isLoaded">
                        <span class="container__list--loader">Carregando...</span>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 sm:col-6 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="scPersonMatrimonialPropertyId">
                        <span translate>label.matrimonial_property</span>
                    </label>
                    <ng-container *ngIf="isLoaded">
                        <sf-sc-person-matrimonial-property-dropdown
                            [id]="form.get('scPersonMatrimonialPropertyId').value"
                            (chosedItem)="reciverFeedbackPersonMatrimonialProperty($event)"
                            [placeHolderLanguage]="'label.matrimonial_property' | translate">
                        </sf-sc-person-matrimonial-property-dropdown>
                    </ng-container>
                    <ng-container *ngIf="!isLoaded">
                        <span class="container__list--loader">Carregando...</span>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-12 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="profession">
                        <span translate>label.profession</span>
                    </label>
                    <input pInputText
                        class="form__item--field p-inputtext-lg"
                        formControlName="profession"
                        id="profession"
                        type="text"
                        class="w-full text-xl"
                    />
                </div>
            </div>
        </div>

        <div class="formgrid grid">
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="motherName">
                        <span translate>label.mother_name</span>
                    </label>
                    <input pInputText
                        class="form__item--field p-inputtext-lg"
                        formControlName="motherName"
                        id="motherName"
                        type="text"
                        class="w-full text-xl"
                    />
                    <!-- <small class="p-invalid" *ngIf="isFieldValid('motherName')">
                        <span translate>label.field_mother_name_required</span>
                    </small> -->
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="fatherName">
                        <span translate>label.father_name</span>
                    </label>
                    <input pInputText
                        class="form__item--field p-inputtext-lg"
                        formControlName="fatherName"
                        id="fatherName"
                        type="text"
                        class="w-full text-xl"
                    />
                    <!-- <small class="p-invalid" *ngIf="isFieldValid('fatherName')">
                        <span translate>label.field_father_name_required</span>
                    </small> -->
                </div>
            </div>
        </div>

        <!-- <div class="formgrid grid">
            <div class="col-12">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="address">
                        <span translate>label.principalAddress</span>
                    </label>
                    <input pInputText
                        class="form__item--field p-inputtext-lg"
                        formControlName="address"
                        id="observation"
                        type="text"
                    />
                </div>
            </div>
        </div> -->

        <div class="formgrid grid">
            <div class="col-12">
                <div class="form__item">
                    <label class="mb-1 font-bold" htmlFor="observation">
                        <span translate>label.observation</span>
                    </label>
                    <input pInputText
                        class="form__item--field p-inputtext-lg"
                        formControlName="observation"
                        id="observation"
                        type="text"
                        class="w-full text-xl"
                    />
                </div>
            </div>
        </div>
    </div>
</form>




<!--
<div class="c-item-registration__footer">
    <div style="float: right; ">
        <div  class="grid">
            <div class="col-12 sm:col-6 md:col-3 lg:col-3">
                <button pButton pRipple
                    icon="pi pi-external-link"
                    style="width: 100%;"
                    class="p-button p-mr-2 mt-3 p-button-lg"
                    [label]="'label.addresses' | translate"
                    (click)="onGoBack()">
                </button>
            </div>
            <div class="col-12 sm:col-6 md:col-3 lg:col-3">
                <button pButton pRipple
                    icon="pi pi-id-card"
                    style="width: 100%;"
                    class="p-button p-mr-2 mt-3 p-button-lg"
                    [label]="'label.document_inserted' | translate"
                    (click)="onGoBack()">
                </button>
            </div>
            <div class="col-12 sm:col-6 md:col-3 lg:col-3">
                <button pButton pRipple
                    icon="pi pi-times"
                    style="width: 100%;"
                    class="p-button-danger p-mr-2 mt-3 p-button-lg"
                    [label]="'button.cancel' | translate"
                    (click)="onGoBack()">
                </button>
            </div>
            <div class="col-12 sm:col-6 md:col-3 lg:col-3">
                <button pButton pRipple
                    icon="pi pi-check"
                    style="width: 100%;"
                    class="p-button-success p-mr-2 mt-3 p-button-lg"
                    [label]="'button.confirm' | translate"
                    (click)="onConfirm()"
                    loadingIcon="pi pi-spin pi-sync"
                    [loading]="loading"
                    [disabled]="!form.valid">
                </button>
            </div>
        </div>
    </div>
</div> -->


