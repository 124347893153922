import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { VMessageModule } from 'src/app/shared/components/vmessage/vmessage.module';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { InputMaskModule } from 'primeng/inputmask';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { DropdownModule } from 'primeng/dropdown';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { ListboxModule } from 'primeng/listbox';
import { ScModuleComponent } from './sc-module-grid/sc-module-grid.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { ScModuleDropdownMultiComponent } from './sc-module-dropdown-multi/sc-module-dropdown-multi.component';
import { ScCompanyModule } from '../sc-company/sc-company.module';

@NgModule({
  declarations: [
    ScModuleComponent,
    ScModuleDropdownMultiComponent
  ],
  exports: [
    ScModuleComponent,
    ScModuleDropdownMultiComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    RouterModule,
    TranslateModule,
    NotificationsModule,
    VMessageModule,
    ButtonModule,
    InputTextModule,
    MessageModule,
    MessagesModule,
    TableModule,
    TooltipModule,
    InputMaskModule,
    CardModule,
    SfDropDownModule,
    DialogModule,
    ConfirmDialogModule,
    DropdownModule,
    ScCompanyModule,
    LoaderModule,
    ListboxModule
  ],
  providers: [
    NotificationsService,
    AuthService,
    AuthorizationGuard
  ]
})
export class ScModuleModule { }
