import { ScUser } from '../../../models/response/sc-user.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { DropDown, DropDownMeta } from 'src/app/shared/model/base-model';
import { UpdateOrderResponsibleInput, WoOrderSorting, WoOrderSortingItem, WoOrderSortingList } from 'src/app/models/response/wo-order-sorting.model';
import { WoOrderItem, WoOrderOutput } from 'src/app/pages/wo-order/wo-order.model';




@Injectable({
    providedIn: 'root'
  })

export class WoOrderSortingService{

    private environmentUrl: string = '';


    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Order/';
    }

    getItem(id: string): Observable<WoOrderItem> {
        let url = this.environmentUrl+"Client/"+ id;
        return this.httpClient.get<WoOrderItem>(url);
    }

    getList() : Observable<WoOrderSortingList>{
        let url = this.environmentUrl+"Sorting/";
        return this.httpClient.get<WoOrderSortingList>(url);
    }




    setResponsible(model: WoOrderSorting, origin: string, responsibleId: string = null){
        let item : UpdateOrderResponsibleInput = {  id: model.id, originType: origin, scUserId: responsibleId};
        let url = this.environmentUrl+"Responsible/"+ model.id;
        return this.httpClient.patch(url, item);
    }


}


