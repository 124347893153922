import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom, of } from "rxjs";
import { ScStateList, ScStateOutput } from "src/app/models/response/sc-state.response";
import { DropDown, DropDownMeta } from "src/app/shared/model/base-model";
import { environment } from "src/environments/environment";






@Injectable({
    providedIn: 'root'
  })
  export class ScStateService {

    environmentUrl = '';

    constructor(private http: HttpClient) {
      this.environmentUrl = environment.ApiCore+"/State/";
     }


    getList() : Observable<ScStateList>{
        let url = this.environmentUrl;
        return this.http.get<ScStateList>(url);
    }

    getListByCountry(countryId: string) : Observable<ScStateList>{
        let url = this.environmentUrl+"?search=ScCountryId="+countryId;
        return this.http.get<ScStateList>(url);
    }

    getItem(id: string) : Observable<ScStateOutput>{
        let url = this.environmentUrl+id;
        return this.http.get<ScStateOutput>(url);
    }


    add(item: ScStateOutput) : Observable<ScStateOutput>{
        let url = this.environmentUrl
        return this.http.put(url, item);
    }

    Upd(item: ScStateOutput) : Observable<ScStateOutput>{
        let url = this.environmentUrl+item.id;
        return this.http.post(url, item);
    }

    getDropDownList(countryId : string): Observable<DropDownMeta>{
        let url = this.environmentUrl + "DropDown?search=ScCountryId=" + countryId;
        return this.http.get<DropDownMeta>(url);
    }
  }
