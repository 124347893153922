<section class="c-item-registration">
    <div class="c-item-registration__header">
        <span class="c-item-registration__header--title">Upload Documentos - {{scPersonName}}</span>
    </div>
    <div class="formgrid grid card">

        <div class="formgrid grid field col-12 sm:col-12 md:col-12 lg:col-12">
            <div [class]="fullScreen == true ? 'field col-12 sm:col-12 md:col-1 lg:col-1 align-content-center' : 'field col-12'">
                <!-- <div class="card flex justify-content-center"> -->
                <!-- <p-fileUpload #fileUpload [disabled]="fieldValidation" name="files[]"
                        (uploadHandler)="onUpload($event)" [multiple]="true" accept="image/*" maxFileSize="1000000"
                        chooseLabel="Adicionar" cancelLabel="Cancelar" chooseStyleClass="p-button-info display-flex mb-1"
                        uploadStyleClass="p-button-info mb-1" cancelStyleClass="p-button-info mb-1" customUpload="false">
                        <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFiles.length">
                                <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
                            </ul>
                        </ng-template>
                    </p-fileUpload> -->
                <p-fileUpload #fileUpload [disabled]="scArchiveTypeId == null || scArchiveTypeId == '00000000-0000-0000-0000-000000000000'" mode="basic" chooseLabel="Adicionar"
                    name="files[]" accept="image/*" maxFileSize="1000000" (uploadHandler)="onUpload($event)" [auto]="true"
                    [multiple]="true" customUpload="true" chooseStyleClass="p-button-info display-flex mb-1"></p-fileUpload>
                <!-- </div> -->
            </div>
            
            <div [class]="fullScreen == true ? 'field col-12 sm:col-12 md:col-2 lg:col-2 align-content-center text-right' : 'field col-12'">
                <span class="font-bold">Tipo Documento:</span>
            </div>

            <div [class]="fullScreen == true ? 'field col-12 sm:col-12 md:col-6 lg:col-6 align-content-center' : 'field col-12'">
                <sf-dropdown-generic [list]="docTypeList" (selectedItemOutput)="reciverFeedbackDocType($event)"
                    [id]="scArchiveTypeId"></sf-dropdown-generic>
                <small class="p-invalid" *ngIf="getFiledValidation(!scArchiveTypeId)"
                    translate>validation_errors.required_field</small>
            </div>

            <div [class]="fullScreen == true ? 'field col-12 sm:col-12 md:col-3 lg:col-3 align-content-center' : 'field col-12'">
                <div class="text-right">
                    <button pButton pRipple icon="pi pi-times" style="width: 120px; margin: 3px;"
                        class="p-button-danger p-mr-2 font-medium text-xl" [label]="'button.cancel' | translate"
                        (click)="cancel();"></button>
                    <button pButton pRipple icon="pi pi-save" style="width: 120px; margin: 3px;"
                        class="p-button-success p-mr-2 font-medium text-xl" [label]="'button.save' | translate"
                        (click)="save();"></button>
                </div>
            </div>

            <div *ngIf="goBackVisible" class="field col-12 sm:col-12 md:col-12 lg:col-12">
                <p-button icon="pi pi-arrow-left" [style]="{'width': '100%'}" styleClass="p-button-info"
                    label="Dados Pessoais - {{scPersonName}}" (click)="onGoBack()"></p-button>
            </div>
        </div>

        <div class="formgrid grid field col-12 sm:col-12 md:col-12 lg:col-12" *ngIf="uploadedFiles.length > 0">
            <div class="col-12 font-bold">
                Arquivos selecionados: {{uploadedFiles.length}} - (<span *ngFor="let file of uploadedFiles; let i = index">{{file.name}}{{(i + 1) < uploadedFiles.length ? ', ' : ''}}</span>)
            </div>
        </div>

        <div [class]="fullScreen == true ? 'field col-12 sm:col-12 md:col-8 lg:col-8' : 'field col-12'">

            <div *ngIf="listPersonFile.length > 0" class="">
                <p-galleria *ngIf="loadFiles" [(visible)]="loadFiles" #galleria [(value)]="listPersonFile"
                    [(activeIndex)]="activeIndex" [numVisible]="5" [showThumbnails]="showThumbnails"
                    [showItemNavigators]="true" [showItemNavigatorsOnHover]="true" [circular]="true" [autoPlay]="false"
                    [transitionInterval]="3000" [containerStyle]="{'width':'100%'}" [containerClass]="galleriaClass()">
                    <ng-template pTemplate="item" let-item>
                        <img [src]="item.pictureUri" style="width: 100%; max-height: 55vh; display: block;" />
                    </ng-template>
                    <ng-template pTemplate="thumbnail" let-item>
                        <div class="grid grid-nogutter justify-content-center">
                            <img height="50" width="50" [src]="item.pictureUri" style="display: block;" />
                        </div>
                    </ng-template>
                    <ng-template pTemplate="footer" let-item>
                        <div class="custom-galleria-footer">
                            <button type="button" pButton icon="pi pi-list" (click)="onThumbnailButtonClick()"></button>
                            <span *ngIf="listPersonFile" class="title-container">
                                <span>{{ activeIndex + 1 }}/{{ listPersonFile.length }}</span>
                                <span class="title">{{ listPersonFile[activeIndex].createdAt | date : 'dd/MM/yyyy
                                    HH:mm:ss'
                                    }} - {{listPersonFile[activeIndex].archiveType}}</span>
                                <span>{{ listPersonFile[activeIndex].userName }}</span>
                            </span>
                            <button type="button" pButton [icon]="fullScreenIcon()" (click)="toggleFullScreen()"
                                class="fullscreen-button"></button>
                        </div>
                    </ng-template>
                </p-galleria>
            </div>


            <label *ngIf="listPersonFile.length == 0" translate>label.empt</label>
        </div>

        <div [class]="fullScreen == true ? 'field col-12 sm:col-12 md:col-4 lg:col-4' : 'field col-12'" style="border: 1px solid black; border-radius: 3px;">
            <div class="card text-center">
                <h5 class="font-bold">Relação de Documentos</h5>
                <div class="formgrid grid card" style="overflow-y: auto;">
                    <ng-container *ngFor="let item of listPersonFile;">
                    <div [class]="fullScreen == true ? 'field col-12 sm:col-12 md:col-1 lg:col-1 align-content-center' : 'field col-2 align-content-center'">
                        <img [src]="item.pictureUri" class="new-order" width="20" height="20">
                    </div>

                    <div [class]="fullScreen == true ? 'field col-12 sm:col-12 md:col-8 lg:col-8 align-content-center text-left' : 'field col-8 align-content-center text-left'">
                        {{item.archiveType}}
                    </div>
                    <div [class]="fullScreen == true ? 'field col-12 sm:col-12 md:col-3 lg:col-3 align-content-center' : 'field col-2 align-content-center'">
                        <button pButton pRipple icon="pi pi-times"
                            class="p-button-danger p-mr-2 font-medium text-xl"
                            (click)="deleteFile(item.id, item.scCompanyId, item.scPersonId, item.archiveType);"></button>
                    </div>
                    </ng-container>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="fileZoomVisible" class="overlay">
        <p-dialog header="Zoom" [(visible)]="fileZoomVisible" [style]="{width: '80vw', height: '80vh'}">
            <div class="card flex justify-content-center">
                <p-image [src]="this.fileZoomUrl" [preview]="true" alt="Image" width="250">
                    <ng-template pTemplate="indicator">
                        <i class="pi pi-check"></i>
                    </ng-template>
                </p-image>
            </div>
        </p-dialog>
    </div>
</section>

<p-confirmDialog key="message.confirm_delete.file" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>