import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {SelectItem} from 'primeng/api';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { SfcWoOrderClientService } from 'src/app/core/services/wo-order/sfc-order-client.service';
import { SfcWoOrderClientOutput } from 'src/app/models/response/sfc-wo-order-client.model';
import { BreadCrumb } from 'src/app/shared/components/sf-breadcrumb/sf-breadcrumb.model';


@Component({
    templateUrl: './mo-cartorio-home-client.component.html',
    styleUrls: ['./mo-cartorio-home-client.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .fc-header-toolbar {
                display: flex;
                flex-wrap: wrap;
            }
        }
    `]
})
export class MoCartorioHomeClientComponent implements OnInit {

    public BREAD_CRUMB_LIST: Array<BreadCrumb> = [];

    public orderList: SfcWoOrderClientOutput[] = [];
    public language: string = "pt-BR";
    public pageMessage: string = "";

    cities: SelectItem[];



    chartData: any;



    fullcalendarOptions: any;

    constructor(private orderClientService: SfcWoOrderClientService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService) {
        // this.breadcrumbService.setItems([
        //     {label: 'Dashboard', routerLink: ['']}
        // ]);
    }

    ngOnInit() {

        this.getList();
        this.translate();
        this.cities = [];
        this.cities.push({label: 'Select City', value: null});
        this.cities.push({label: 'New York', value: {id: 1, name: 'New York', code: 'NY'}});
        this.cities.push({label: 'Rome', value: {id: 2, name: 'Rome', code: 'RM'}});
        this.cities.push({label: 'London', value: {id: 3, name: 'London', code: 'LDN'}});
        this.cities.push({label: 'Istanbul', value: {id: 4, name: 'Istanbul', code: 'IST'}});
        this.cities.push({label: 'Paris', value: {id: 5, name: 'Paris', code: 'PRS'}});

        this.chartData = {
            labels: ['0', '1', '2', '3', '4', '5', '6'],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [, 6, 3, 2, 7, 9, ],
                    fill: false,
                    borderColor: '#FFC107',
                    tension: .4
                },
                {
                    label: 'Second Dataset',
                    data: [, 2, 1, 3, 6, 8, ],
                    fill: false,
                    borderColor: '#03A9F4',
                    tension: .4
                }
            ]
        };

        this.fullcalendarOptions = {
            initialDate: '2021-02-01',
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,
        };
    }

    getList() {
        this.orderClientService.getList().subscribe(
            {
                next: (res) => {
                    if (res)
                        this.orderList = res.data;
                },
                error: (err) => {
                    console.log(`SfcWoOrderClientGrid - getList : ${err}.`);
                }
            });
    }

    getOrder(item: any){

    }

    translate()
    {
        this.language = this.translateConfigService.getLanguage();

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
             await sleep(50);
            //  const lab0 = this.translateService.instant('label.home') + ' ';
            //  const lab1 = ' ' + this.translateService.instant('label.serviceSorting');

            // this.BREAD_CRUMB_LIST = [
            //     {label: lab0,routerLink: '/',selected: false},
            //     {label: lab1,routerLink: '/order/order-sorting',selected: true}
            // ]


             this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')
         })();



    }

}
